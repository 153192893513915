import React, { useEffect, useState } from 'react';
import { getPermitUsers } from '../../../../../../api/users';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';
import { UserType } from '../../../../../../api/userAuth';
import styled from 'styled-components';
import { setAllContacts, setContact, setErrors } from '../../../../../../redux/slices/InspectionsReportSlice';

const ContactDropdown = ({ inspection }) => {
  const { selectedPermit } = useSelector((s: RootState) => s.inspections);
  const { contact, errors, allContacts } = useSelector((s:RootState) => s.inspectionReports)
  const dispatch = useDispatch();
  useEffect(() => {
    const load = async () => {
      const result = await getPermitUsers({
        user_role: 3,
        permit_id: selectedPermit.permitId,
      });

      //   const options = result.data.map(item => ({
      //     label: `${item.companyName} ${item.firstName} ${item.lastName}`.replace(/null null/, ''),
      //     value: `${item.id}`,
      //   }));
      dispatch(setAllContacts(result.data));
    };
    if (Object.keys(selectedPermit).length > 0) {
      load();
    }
  }, [selectedPermit]);

  const onChange = e => {
    const selected = allContacts.find(c => c.id === Number(e.target.value));
    dispatch(setContact(selected));
    const _errors = {...errors,'contactPhone':null}
    dispatch(setErrors(_errors));
  }
  return (
    <Select onChange={onChange} value={contact.id}>
      {allContacts.map((item, index) => {
        const value = `${item.companyName} ${item.firstName} ${item.lastName}`;
        return (
          <option value={item.id} key={index}>
            {value}
          </option>
        );
      })}
    </Select>
  );
};

export default ContactDropdown;

const Select = styled.select`
  width: 100%;
  height: 100%;
  border: none;
  &:focus-visible {
    border: none;
    outline: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  div > .react-select__control {
    border-radius: 9px;
    margin-top: 6px;
    margin-left: -2px;
  }
`;

const Error = styled.div`
  color: red;
`;

const Label = styled.div<{ error: boolean }>`
  font-size: 18px;
  color: ${({ error }) => (error ? 'red' : '#316B99')};
`;

const InputWrapper = styled.div<{ readOnly: boolean; error?: boolean }>`
  border: ${({ error }) => (error ? '1px solid red' : ' 1px solid grey')};
  background-color: ${({ readOnly }) => (readOnly ? '#efefef' : '#fff')};
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  input {
    border: none;
  }
  .react-datepicker-ignore-onclickoutside {
    outline: none;
    border: none;
    &:focus-visible {
      outline: none;
      border: none;
    }
  }
`;

const Value = styled.div`
  color: grey;
  height: 20px;
`;
