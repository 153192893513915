import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { setSelectedInvoicesDataToPay, setTotalStripeAmount } from '../../../../../redux/slices/feesSlice';
import { currency } from '../../../utils';
import BigScreenTable from './InvoicesTable/BigScreenTable';
import { TableWrapper } from '../../Common/Table/types';
import SmallScreenTable from './InvoicesTable/SmallScreenTable';

export const PHONE = '480px';
export const TABLET = '850px';
export const CCFee = 0.0299;

const StripeInvoicesTable = () => {
  const { selectedProjectInvoice, selectedInvoicesToPay, selectedInvoicesDataToPay, totalStripeAmount } = useSelector(
    (s: RootState) => s.fees,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const clearInvoiceNumber = invoiceNumber => {
      if (invoiceNumber.indexOf('----') > -1) {
        return invoiceNumber.split('----')[0];
      }
      return invoiceNumber;
    };
    if (selectedProjectInvoice.permit) {
      const { invoices: _invoices } = selectedProjectInvoice.permit;
      let _total = 0;
      const tableData = _invoices.reduce((invoiceArr, invoice) => {
        if (selectedInvoicesToPay[invoice.id]) {
          _total += Number(invoice.total);
          invoiceArr.push({ ...invoice, invoiceNumber: clearInvoiceNumber(invoice.invoiceNumber) });
        }
        return invoiceArr;
      }, []);
      dispatch(setSelectedInvoicesDataToPay(tableData));
      dispatch(setTotalStripeAmount(_total * (1 + CCFee)));
    }
  }, [selectedProjectInvoice]);
  return (
    <TableWrapper>
      <Wrapper>
        <Message>You have selected to pay the following invoices:</Message>

        <HideOnPhone>
          <BigScreenTable invoices={selectedInvoicesDataToPay} />
        </HideOnPhone>
        <ShowOnPhone>
          <SmallScreenTable invoices={selectedInvoicesDataToPay} />
        </ShowOnPhone>

        <TotalCell>Total Amount to Pay: {currency(totalStripeAmount)}</TotalCell>

        <MessageWrapper>
          To avoid credit card fees, you may send a check directly to our office before the due date. The office address is provided on the
          invoice. Please note that ACH transfer is not available at this time.
        </MessageWrapper>
      </Wrapper>
    </TableWrapper>
  );
};

export default StripeInvoicesTable;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: rgb(80, 80, 80);
  margin-top: 30px;
  margin-left: 20px;
`;

const Message = styled.div`
  font-size: 20px;
`;

const MessageWrapper = styled.div`
  font-size: 17px;
  margin: 149px;
  margin-top: 15px;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  line-height: 22px;
  font-style: italic;
`;

const HideOnPhone = styled.div`
  @media only screen and (max-width: ${PHONE}) {
    display: none;
  }
`;

const ShowOnPhone = styled.div`
  display: none;
  @media only screen and (max-width: ${PHONE}) {
    display: inherit;
  }
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 180px 1fr;
  grid-gap: 2px;
  background-color: rgb(180, 180, 180);
  width: 98%;
  border: 2px solid rgb(180, 180, 180);
  border-radius: 6px;
  @media only screen and (max-width: ${TABLET}) {
    grid-template-columns: 200px 200px 1fr;
  }
  @media only screen and (max-width: ${PHONE}) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;

const TotalCell = styled.div`
  grid-column: 4/5;
  font-weight: bold;
  text-align: right;
  padding-top: 15px;
  padding-right: 55px;
  font-size: 20px;
`;
