import axios from 'axios';
import { getAccessToken } from '../auth';
import { PaginatedList } from '../types';
import { formatToFormData } from '../utils';
import { api } from './api';
import { FranchiseType } from './franchise';
import {
  onResponseErrorInterceptor,
  onResponseInterceptor,
} from './interceptors';
import { JurisdictionType } from './jurisdiction';

export const createPermitInspection = data => {
  const formData = new FormData();
  if (data.report) {
    formData.append('report', data.report);
  }
  formData.append('title', data.title);
  formData.append('inspection_type', data.inspectionType);
  formData.append('is_emergency', data.isEmergency);
  formData.append('permit', data.permit);
  formData.append('trades', data.trades);
  formData.append('contact', data.contact);
  formData.append('cell_phone', data.cellPhone);
  formData.append('phone_number', data.phoneNumber);
  formData.append('information', data.information);
  return api.post(
    `/inspections/`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};


export const sendInspectionRequestEmail = data => {
  return api.request({
    method: 'POST',
    url: '/inspections/email/',
    data: {
      sendType: 'Inspection request',
      inspections: data.inspections,
    },
  });
};

export const sendInspectionMessage = data => {
  return api.request({
    method: 'POST',
    url: '/inspections/trigger-message',
    data: data
  });
};

export const updatePermitInspection = data => {
  return api.request({
    method: 'PUT',
    url: `/inspections/${data.id}/`,
    data,
  });
};

export const getPermitInspections = params => {
  return api.request<InspectionsTypeList>({
    method: 'GET',
    url: `/inspections/`,
    params,
  });
};

export const getServerTime = () => {
  return api.request<InspectionsTypeList>({
    method: 'GET',
    url: `/inspections/get_est_time/`,
  });
};

export const getPendingInspectionsCount = () => {
  return api.request({
    method: 'GET',
    url: `/inspections/pending-inspections-count/`,
  });
};

export const getPendingReviewsCount = () => {
  return api.request({
    method: 'GET',
    url: `/project_activities/pending-review-count/`,
  });
};

export const getPermitInspectionById = params => {
  return api.request<InspectionsTypeList>({
    method: 'GET',
    url: `/inspections/${params.id}/`,
    params,
  });
};

export const getPermitInspectionsComments = (params, id) =>
  api.request({
    method: 'GET',
    url: `/inspections/${id}/comments/`,
    params,
  });

export const getInspectionsByClient = (client_id) =>
api.request({
  method: 'GET',
  url: `/client_projects/get-all-inspections`,
});

export const createPermitInspectionsComment = (
  data: any,
) => {

  const { id } = data;
  const body = new FormData();
  body.append('text',data.text);
  if(data.file){
     body.append('file', data.file);
  }

  return api.request({
    method: 'POST',
    url: `/inspections/${id}/comments/`,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'multipart/form-data',
    },
    data:body,
  });

};

export type InspectionsTypeList = PaginatedList<
  InspectionsType
>;

export interface InspectionsType {
  id?: number;
  name: string;
  isActive: boolean;
  isDeleted?: boolean;
  franchiseNames: string;
  franchise: number[] | FranchiseType[];
  jurisdiction: number[] | JurisdictionType[];
  jurisdictionNames: string;
  amount: string;
  feeServiceType: number;
  skuCode: string;
  description: string;
  isFranchiseAll: boolean;
  feeType?: number | string;
  reimburse: boolean;
  employeeCanEdit: boolean;
  isDefault?: boolean;
  inspector?: number;
  inspectionDate?: string;
}
