import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { setShowFormPopup } from '../../redux/slices/InspectionsReportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { createPermitInspectionsComment, getPermitInspectionsComments } from '../../api/inspections';
import { RootState } from '../../redux/root';
import { GET_ALL_QUERY_PARAMS } from '../../constants';

const StatusChangeConfirmPopup = ({ setPromptValue, undoStatusChange }) => {
  const info = useSelector((s: RootState) => s.userAuth.info);
  const message = useSelector((s: RootState) => s.inspectionReports.propmtMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    setPromptValue(false,false);
  }, [])
  const onNo = () => {
    dispatch(setShowFormPopup(false));
    undoStatusChange();
  };

  const onYes = async () => {
    if(message.startsWith('Permit Status')){
      setPromptValue(true,false);
    } else {
      setPromptValue(false,true);
    }
    dispatch(setShowFormPopup(false));
  };

  return (
    <WrapperContainer>
      <Wrapper>
        <Body>
          <Message>            
            <p>{message} </p>
            <p>Are you sure you want to schedule the inspection?</p>
          </Message>
          <Buttons>
            <Button color="primary" variant="contained" onClick={onNo}>
              No
            </Button>
            <Button color="primary" variant="contained" onClick={onYes}>
              Yes
            </Button>
          </Buttons>
        </Body>
      </Wrapper>
    </WrapperContainer>
  );
};

export default StatusChangeConfirmPopup;

const WrapperContainer = styled.div`
  position: absolute;
  width: 91%;
  margin: auto;
  z-index: 3;
`;
const Wrapper = styled.div`
  box-shadow: 7px 7px 13px darkgrey;
  width: 437px;
  height: 178px;

  background-color: #fff;
  border-radius: 4px;
  border: 1px solid grey;
  margin: auto;
  margin-top: 40px;
`;

const Body = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Buttons = styled.div`
  width: 80%;
  height: 48px;
  display: flex;
  margin: auto;
  margin-bottom: 18px;
  justify-content: space-around;
  button {
    width: 140px;
    height: 30px;
  }
`;
const Message = styled.div`
  flex: 1;
  width: 100%;
  text-align: center;
  padding-top: 18px;
  p{
    margin: 0 10px;
  }
`;
