import * as React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import Icon from '@material-ui/core/Icon';

import UsersIcon from '../resources/icons/users_page.svg';
import SearchIcon from '../resources/icons/search_page.svg';
import UserPageIcon from '../resources/icons/user_page.svg';
import DashboardIcon from '../resources/icons/home_page.svg';
import ReportsIcon from '../resources/icons/report_page.svg';
import AllActivitiesIcon from '../resources/icons/fact_check.svg';
// import AddProjectIcon from '../resources/icons/add_icon.svg';

import { Logo } from '../atoms';
import { SidebarMenuLink } from '../molecules';
import { ROUTES } from '../constants/routes';

export const createIcon = (src: string, scale: number = 1) => (
  <IconWrapper scale={scale}>
    <Icon>
      <ReactSVG src={src} />
    </Icon>
  </IconWrapper>
);

const EmployeeSidebar = ({ logoClassName }: Props) => {
  const MenuItems = {
    Dashboard: 'Dashboard',
    Reports: 'Reports',
    Contacts: 'Contacts',
    Search: 'Search',
    Account: 'Account',
    AllActivities: 'All Activities',
    // AddProject: 'Add Project',
  };

  return (
    <Wrapper>
      <RootLogo className={logoClassName}>
        <Logo />
      </RootLogo>

      <MenuWrapper>
        <SidebarMenuLink
          icon={() => createIcon(DashboardIcon)}
          to={ROUTES.DASHBOARD}
          title={MenuItems.Dashboard}
        />
        <SidebarMenuLink
          icon={() => createIcon(AllActivitiesIcon)}
          to={ROUTES.ALL_ACTIVITIES}
          title={MenuItems.AllActivities}
        />
        <SidebarMenuLink
          icon={() => createIcon(ReportsIcon)}
          to={ROUTES.REPORTS}
          title={MenuItems.Reports}
        />
        <SidebarMenuLink
          icon={() => createIcon(UsersIcon)}
          to={ROUTES.CONTACTS}
          title={MenuItems.Contacts}
        />
        <SidebarMenuLink
          icon={() => createIcon(SearchIcon)}
          to={ROUTES.SEARCH}
          title={MenuItems.Search}
        />
        <SidebarMenuLink
          icon={() => createIcon(UserPageIcon)}
          to={ROUTES.ACCOUNT}
          title={MenuItems.Account}
        />
      </MenuWrapper>
    </Wrapper>
  );
};

const RootLogo = styled.div`
  padding: 20px 14px;
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 80px;
  background: var(--primary);
  padding-top: 15px;
  transition: width 0.5s;
`;

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  width: 100%;
  min-height: 500px;
`;

const IconWrapper = styled.div<{scale?:number}>`
  .MuiIcon-root {
    margin: 0;
    display: block;

    svg {
      width: 40px;
      height: 40px;
      scale: ${({scale}) => scale === 1 ? '1' : `${scale}` }
    }
  }
`;

interface Props {
  logoClassName?: string;
}

export default EmployeeSidebar;
