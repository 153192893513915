import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Done from '@material-ui/icons/Done';
import { RecordType } from './ReviewerCommentForm1';
import ReferenceDropdown from './ReferenceDropdown';
import { getReferenceCodes } from '../../api/referenceCodes';
import { DEFAULT_PARAMS_ALL } from '../../constants';

type Props = {
  record: RecordType;
  onChange: Function;
  onCompoundChange: Function
  saveAll: any;
  statuses: any[];
  showSave: boolean;
  initialValues: any;
};
const ReviewRecord: FC<Props> = ({ record, onChange, onCompoundChange, saveAll, statuses, showSave, initialValues }) => {
    const [errors, setErrors] = useState({});
    const [codeNotes, setCodeNotes] = useState([]);
useEffect(() => {
  const load = async() => {
    const result = await getReferenceCodes(DEFAULT_PARAMS_ALL);
    const _codeNotes = result?.data?.results.filter((r:any) => {
      const reviewTypeId = Number(initialValues.reviewTypeId);
      const ndx = r.tradeIds.indexOf(reviewTypeId);
      return ndx > -1;
    });
    setCodeNotes(_codeNotes)
  }
  load();
}, [])
    const save = () => {
        setErrors({});
        if(!record.comment){
            setErrors({comment:"empty"});
            return;
        }
        if(!record.reference){
            setErrors({reference:"empty"});
            return;
        }        
        saveAll();
    }

    const getStatus = (stt) => {
      if(isNaN(Number(stt))){
        return stt.value;
      }
      const status = statuses.find(s => s.value === Number(stt));
      return status.value;
    }
  return (
    <CommentRow zebra={record.index % 2}>
      <div></div>
      <Label>
        Reference Code<Red>*</Red>
      </Label>
      <Label>
        Reviewer Comment<Red>*</Red>
      </Label>
      
      <Label>
        Status<Red>*</Red>
      </Label>
      <div></div>

      <Counter>{record.index}.</Counter>
      <ReferenceDropdown codeNotes={codeNotes} errors={errors} record={record} onChange={e => onChange(e, record.index)} onCompoundChange={onCompoundChange}/>
      <TextWrapper error={errors['comment'] !== undefined}>
        <textarea name="comment" value={record.comment} onChange={e => onChange(e, record.index)} />
      </TextWrapper>   
      <div>
        <select value={getStatus(record.status)} name="status" onChange={e => onChange(e, record.index)}>
          {statuses.map((status,index) => <option key={index} value={status.value}>{status.label}</option> )}
        </select>
      </div>
      <Button onClick={save}>
        {!showSave && <Done />}
        {showSave && <Save>Saved</Save>}
      </Button>
    </CommentRow>
  );
};

export default ReviewRecord;

const CommentRow = styled.div<{ zebra: number }>`
  display: grid;
  grid-template-columns: 26px repeat(2, 1fr) 150px 150px;
  background-color: ${({ zebra }) => (zebra === 1 ? '#E8F0FE' : 'white')};
  border-radius: 6px;
  textarea {
    width: 100%;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 16px;
    padding-bottom: 0;
    color: #666;
    padding-top: 11px;
    height: 44px;
    line-height: 27px;
    padding-left: 10px;
    position: relative;
    top:-1px;
  }
  input {
    width: 100%;
    height: 100%;
    color: #666;
    font-size: 16px;
    padding-left: 12px;
    outline: none;
    border: none;
  }
  select {
    width: 100%;
    height: 73%;
    font-size: 16px;
    color: #666;
    padding-left: 8px;
  }
`;

export const TextWrapper = styled.div<{error:boolean}>`
 border: ${({error}) => error ? '1px solid red' : ''};
 margin-bottom: 12px;
`;
const Red = styled.div`
  color: red;
`;

const Counter = styled.div`
  text-align: right;
  padding-right: 3px;
`;

const Label = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Button = styled.div`
  height: 73%;
  padding-top: 10px;
  scale: 1.3;
  &:hover {
    background-color: #bbc1ca;
    cursor: pointer;
    scale: 1;
  }
`;

const Save = styled.div`
 color: green;
`;