import React, { FC } from 'react';
import { Button, Drawer } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { setBottomDrawerOpened } from '../../../../../redux/slices/generalSlice';

type Props = {
  onSubmit: any;
  submitLabel: string;
  submitDisabled?: boolean;
  disableMessage?: string;
  disableFromHold?: boolean;
};
const BottomDrawer: FC<Props> = ({
  onSubmit,
  submitLabel,
  submitDisabled = false,
  disableMessage = '',
  disableFromHold = false,
  children,
}) => {
  const dispatch = useDispatch();
  const bottomDrawerOpened = useSelector((s: RootState) => s.general.bottomDrawerOpened);
  const onClose = () => {
    dispatch(setBottomDrawerOpened(false));
  };
  return (
    <Drawer anchor={'bottom'} open={bottomDrawerOpened} transitionDuration={750} onClose={onClose}>
      <Wrapper>
        <Area>
          <CloseIcon onClick={onClose}>
            <HighlightOffIcon />
          </CloseIcon>
        </Area>
        <Area>{children}</Area>
        <Area>
          <ButtonsArea>
            <Button disabled={submitDisabled || disableFromHold} variant="contained" color="primary" onClick={onSubmit}>
              {submitLabel}
            </Button>
            {disableFromHold && <Message>{disableMessage}</Message>}
          </ButtonsArea>
        </Area>
      </Wrapper>
      {/* </form> */}
    </Drawer>
  );
};

export default BottomDrawer;

const Wrapper = styled.div`
  position: relative;
  overflow: auto;
  height: 89vh;
  margin: 17px;
  margin-inline: 100px;
  display: grid;
  grid-template-rows: 40px 1fr 75px;
  border: 1px solid grey;
  border-radius: 8px;
  box-shadow: 10px 10px 10px grey;
  @media only screen and (max-width: 840px) {
    margin-inline: 0;
  }
`;

const Message = styled.div`
  font-size: 15px;
  color: red;
`;

const Area = styled.div`
  width: 100%;
  position: relative;
`;

const CloseIcon = styled.div`
  height: 7px;
  position: absolute;
  right: 10px;
  top: 10px;
  zoom: 1.5;
  cursor: pointer;
  z-index: 3;
`;

const ButtonsArea = styled.div`
  position: absolute;
  width: 100%;
  bottom: 28px;
  margin: auto;
  display: flex;
  margin-top: 26px;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  button {
    margin: auto;
    width: 250px;
  }
`;
