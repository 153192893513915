import React, { useEffect, useRef, useState } from 'react';
import { TextWrapper } from './ReviewRecord';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import { setPulse } from '../../redux/slices/generalSlice';
import FeeFileAttachment from '../PermitFeeForm/FeeFileAttachment';

const ReferenceDropdown = ({ errors, record, onChange, onCompoundChange, codeNotes }) => {
  const [open, setOpen] = useState<boolean>(false);
  const pulse = useSelector((s: RootState) => s.general.pulse);
  const pulseRef = useRef(0);
  const ref = useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (pulse !== pulseRef.current) {
      setOpen(false);
    }
  }, [pulse]);

  const makePulse = () => {
    const now = new Date().getTime();
    pulseRef.current = now;
    dispatch(setPulse(now));
  };
  const itemClicked = (e, item) => {
    const _e = {
      target: {
        name: 'comment',
        value: item.comments,
      },
    };

    const _e1 = {
      target: {
        name: 'reference',
        value: item.name,
      },
    };
    onCompoundChange('comment', item.comments, 'reference', item.name, record.index);
    setOpen(false);
    if (pulse !== pulseRef.current) {
      makePulse();
    }
  };

  const openDropdown = () => {
    setOpen(!open);
    makePulse();
  };
  return (
    <TextWrapper error={errors['reference'] !== undefined}>
      <InputWithDropdown>
        <input
          type="text"
          name="reference"
          autoComplete="off"
          value={record.reference}         
          onChange={e => onChange(e, record.index)}
        />
        <Icon onClick={openDropdown}>{open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</Icon>
      </InputWithDropdown>
      {open && (
        <List onMouseLeave={e => setOpen(false)}>
          {codeNotes.map((item, index) => {
            return (
              <ListItem ref={ref} onClick={e => itemClicked(e, item)} key={index}>
                {item.name}
              </ListItem>
            );
          })}
        </List>
      )}
    </TextWrapper>
  );
};

export default ReferenceDropdown;

const InputWithDropdown = styled.div`
  border: 1px solid var(--defaultShadow);
  height: 92%;
  padding-right: 29px;
  background-color: #fff;
  display: flex;
`;

const Icon = styled.div`
  scale: 1.5;
  width: 20px;
  position: relative;
  top: 14px;
  right: -17px;
`;

const List = styled.div`
  border: 1px solid grey;
  width: 271px;
  height: 100px;
  position: absolute;
  background-color: #fff;
  margin-top: 2px;
  overflow: auto;
  text-align: left;
`;

const ListItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: var(--border);
  }
`;
