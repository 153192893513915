import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import InspectionsControls from './Controls/InspectionsControls';
import { getServerTime } from '../../../../../api/inspections';
import { validateInspectionDate } from '../../../utils';
import { format } from 'date-fns';
import { setInspectionApiResults, setPermitInspectionsList, setSelectedInspection, setSelectedPermit } from '../../../../../redux/slices/inspectionsSlice';
import { InspectionItemType } from '../../../types';
import BottomDrawer from '../../Common/Containers/BottomDrawer';
import { setBottomDrawerOpened } from '../../../../../redux/slices/generalSlice';
import { InspectionStatuses } from '../../../../../constants';
import { setErrors, setNotes } from '../../../../../redux/slices/InspectionsReportSlice';
import { useUpdateInspectionsMutation } from '../../../../../redux/rtkQuery/apiSlice';

const EditInspectionPopup: FC<any> = ({updateNewStatus, disableFromHold}) => {
  const { selectedInspection, selectedPermit, permitInspectionsList } = useSelector((s: RootState) => s.inspections);
  const { contact, errors, contactPhone, notes } = useSelector((s: RootState) => s.inspectionReports);
  const schedules = useSelector((s: RootState) => s.general.schedules);
  const [inspectionDate, _setInspectionDate] = useState<string>('');
  const [showClose, setShowClose] = useState<boolean>(false);
  const [updateInspections] = useUpdateInspectionsMutation();
  const dispatch = useDispatch();

  const setInspectionDate = (data: string) => {
    _setInspectionDate(data);
    setShowClose(false);
    dispatch(setInspectionApiResults({ apiMessage: undefined, isApiError: false }));
  };

  useEffect(() => {
    setInspectionDate(selectedInspection.inspectionDate);
    dispatch(setNotes(selectedInspection.information));
  }, [selectedInspection]);

  const onUpdate = async () => {
    // const _now = new Date(2024, 11, 11, 17, 30, 45, 0);
    // const inspDate = new Date(2024, 11, 12 );
    // debugger;
    // const x = validateInspectionDate(new Date(inspDate), schedules, _now);
    // return;

    if (showClose) {
      setShowClose(false);
      dispatch(setBottomDrawerOpened(false));
      return;
    }
    const reductedInspection = { ...selectedInspection };
    delete reductedInspection.report;
    const _inspection = {
      id: reductedInspection.id,
      contact: contact.id,
      phone_number: contact.phoneNumber,
      information: notes,
      inspection_date: format(new Date(inspectionDate), 'MM/dd/yyyy'),
      status: 29// ready fo scheduling
    };

    const estTime: any = await getServerTime();
    const now = estTime?.data?.estTime ? new Date(estTime.data.estTime) : new Date();

    let validation = validateInspectionDate(new Date(inspectionDate), schedules, now);
    let _errors = { ...errors };
    if (validation !== null) {
      _errors = { ..._errors, inspectionDate: validation };
      dispatch(setErrors(_errors));
    }

    const phoneRegex = /^[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]$/;
    if (!phoneRegex.test(contactPhone)) {
      validation = 'Phone has to follow the pattern ###-###-####';
      _errors = { ..._errors, contactPhone: validation };
      dispatch(setErrors(_errors));
    }
    if (validation === null) {
      try {
        const result: any = await updateInspections(_inspection);
        dispatch(setInspectionApiResults({ apiMessage: 'Success', isApiError: false }));
        updateNewStatus(_inspection.id);
        setShowClose(true);

        let _selectedPermit;
        const updated = permitInspectionsList.map(p => {
          if (p.permitId === result.data.permit) {
            _selectedPermit = {
              ...p,
              inspections: p.inspections.map(pi => {
                if (pi.id === result.data.id) {
                  return {
                    ...pi,
                    status: result.data.status,
                    statusName: result.data.statusName,
                    inspectionDate: result.data.inspectionDate,
                    information: result.data.information,
                    contact: result.data.contact,
                    contactFirstName: result.data.contactFirstName,
                    contactLastName: result.data.contactLastName,
                  };
                }
                return pi;
              }),
            };
            return _selectedPermit;
          }
          return p;
        });
        //updating the selected permit, so the UI gets refreshed
        dispatch(setPermitInspectionsList(updated));
        dispatch(setSelectedPermit(_selectedPermit));
      } catch (e) {
        setShowClose(true);
        if (e && e.response) {
          dispatch(setInspectionApiResults({ apiMessage: JSON.stringify(e.response.data), isApiError: true }));
        }
      }
    }
  };
  return (
    <BottomDrawer 
         submitDisabled={Object.keys(contact).length === 0 } 
         disableFromHold={disableFromHold}
         disableMessage="Inspection cannot be scheduled. This permit has a hold on it, check conditions or contact office for more information."
         submitLabel= {showClose ? "Close" : "Schedule"} 
         onSubmit={onUpdate}>
      <ControlsArea>
        <ControlsLabel>Update Inspection</ControlsLabel>
        <InspectionsControls inspection={selectedInspection} setInspectionDate={setInspectionDate} />
      </ControlsArea>
    </BottomDrawer>
  );
};

export default EditInspectionPopup;

const ControlsArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 30px 1fr;
  position: relative;
  top: -30px;
`;

const ControlsLabel = styled.div`
  text-align: center;
  font-size: 25px;
  font-weight: bold;
`;
