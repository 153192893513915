import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReviewRecord from './ReviewRecord';
import { createReviewerComment } from '../../api/activities';
import { getReviewerComments } from '../../api/projectDocuments';
import { InspectionStatuses } from '../../constants';

export type RecordType = {
  comment: string;
  reference: string;
  status: any;
  index: number;
  id: number;
};
const ReviewerCommentForm1 = ({ reviewId, values }) => {
  const [records, setRecords] = useState<RecordType[]>([]);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [ids, setIds] = useState<number[]>([]);
  const [statuses] = useState([
    { label: InspectionStatuses.REJECTED, value: 0 },
    { label: InspectionStatuses.APPROVED, value: 1 },
    { label: InspectionStatuses.NOT_APPLICABLE, value: 2 },
  ]);
  useEffect(() => {
    const load = async () => {
      await getReviewerComments(reviewId);
      const response = await getReviewerComments(reviewId);
      const _records = response.data.map((d, ndx) => {
        const status = statuses.find(s => s.label === d.status);
        return {
          comment: d.reviewerComment,
          reference: d.reference,
          status,
          index: ndx + 1,
          id: d.id,
        };
      });
      setRecords(_records);
    };
    load();
  }, []);

  const getStatus = value => {
    if (isNaN(Number(value))) {
      return value;
    }

    const _status = statuses.find(s => s.value === Number(value));
    return _status;
  };
  const saveAll = async () => {
    const getStatus = stt => {
      if (isNaN(stt)) {
        return stt;
      }
      const status = statuses.find(s => s.value === Number(stt));
      return status;
    };
    const data = {
      reviewId: reviewId,
      reviewerCommentForm: records.map((r,ndx) => {
        const getId = (index,r_id) => {        
          //initial case before we called save (checkmark)
          if(ids.length === 0){
            return r_id;
          }      
          
          if(index < ids.length){
            return ids[index];
          }

          return 0;
        }
        return {
          id: getId(ndx,r.id),
          reference: r.reference,
          reviewer_comment: r.comment,
          status: getStatus(r.status),
        };
      }),
    };
    const result = await createReviewerComment(data);
    const _data:any = result.data || {updatedCommentIds:[]}
    setIds(_data.updatedCommentIds);
    setShowSave(true);
    setTimeout(() => {
      setShowSave(false);
    }, 1000);
  };
  const onChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;

    const _ndx = index - 1;
    const _records = records.map((r, ndx) => {
      if (ndx === _ndx) {
        return {
          ...r,
          [name]: value,
        };
      }
      return r;
    });

    setRecords(_records);
  };

  const onCompoundChange = (name1, value1, name2, value2, index) => {
    const _ndx = index - 1;
    const _records = records.map((r, ndx) => {
      if (ndx === _ndx) {
        return {
          ...r,
          [name1]: value1,
          [name2]: value2,
        };
      }
      return r;
    });

    setRecords(_records);
  };

  const onClick = () => {
    const status = statuses.find(s => s.value === 0);
    const _rec: RecordType = {
      comment: '',
      reference: '',
      status,
      index: records.length + 1,
      id: 0,
    };
    const _records = [...records];
    _records.push(_rec);
    setRecords(_records);
  };
  return (
    <Wrapper>
      <Header>Review Comments</Header>
      <Spacer></Spacer>
      {records.map(r => (
        <ReviewRecord
          showSave={showSave}
          record={r}
          onChange={onChange}
          onCompoundChange={onCompoundChange}
          statuses={statuses}
          initialValues={values}
          saveAll={saveAll}
        />
      ))}
      <NewButton onClick={onClick}>+ New</NewButton>
    </Wrapper>
  );
};

export default ReviewerCommentForm1;

const Wrapper = styled.div`
  position: relative;
`;

const NewButton = styled.div`
  color: var(--primary);
  width: 100%;
  text-align: left;
  cursor: pointer;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 28px;
  position: absolute;
  top: -31px;
  padding-left: 2px;
`;

const Spacer = styled.div`
 height: 24px;
`;