import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../redux/root';
import { CustomParams } from '../../../modules/clientModule/types';
import { useGetProjectDocumentsQuery, useGetVocabularyQuery } from '../../../redux/rtkQuery/apiSlice';
import { setRowsCount } from '../../../redux/slices/paginationSlice';
import { Loader } from '../../../atoms';
import { Pagination } from '../../../modules/clientModule/components/Common/Table/types';
import PaginationMobile from '../../Responsive/PaginationMobile';
import SearchInput from '../../../Components/TableWithFilters/SearchInput';
import StatusDropdown from './StatusDropdown';
import SortingHeader from './SortingHeader';
import DocumentActions from './DocumentActions';
import FileEntry from './FileEntry';
import { setCheckedBoxes } from '../../../redux/slices/documentsSlice';
import { someDocumentsSelected } from '../../../utils/documentsUtil';

//from ProjectsTable
const DocumentsTable = ({ permitId, handleEdit, setRefetchParams }) => {
  const dispatch = useDispatch();
  const [params, setParams] = useState<any>();
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const { data: documents, isFetching, refetch } = useGetProjectDocumentsQuery(params, { skip: params === undefined });
  const { pageCount, pageIndex, pageSize } = useSelector((s: RootState) => s.pagination);
  const mcheckedBoxes = useSelector((s:RootState) => s.documents.mcheckedBoxes);
  const filters = useSelector((s: RootState) => s.filters.filters);
  const { data: voca } = useGetVocabularyQuery(10);
  const [rows, setRows] = useState([]);
  const _params: CustomParams = {
    is_deleted: false,
    limit: pageSize,
    offset: pageIndex * pageSize,
    permit_id: permitId,
    event_type: 'Activity',
    activity_type_name: 'Document',
  };
  useEffect(() => {
    if (permitId) {
      setParams(_params);
      setRefetchParams(_params);
    }
  }, [permitId]);

  useEffect(() => {
    let _params1: any = { ..._params, limit: pageSize };
    if (filters) {
      filters.forEach(f => {
        _params1[f.filterName] = f.filterValue;
      });
    }
    // if (searchToken && pageIndex > 0) {
    //   _params1.offset = pageIndex * pageSize;
    //   _params1.search = searchToken;
    // }
    // else {
    //   _params = searchToken
    //     ? { ..._params, search: searchToken }
    //     : {
    //         ..._params,
    //         offset: pageIndex * pageSize,
    //       };
    //}
    setParams(_params1);
  }, [pageSize, pageIndex, pageCount, filters]);

  useEffect(() => {
    if (documents && documents.results.length > -1) {
      dispatch(setRowsCount(documents.length));
      setRows(documents.results);
    }
  }, [documents]);

  useEffect(() => {
    if(Object.keys(mcheckedBoxes).length === 0) {
      setAllChecked(false)
    }
  }, [mcheckedBoxes])
  const onChange = (e, pk) => {
    const isChecked = e.target.checked;

    const _checkedItems = { ...mcheckedBoxes };
    _checkedItems[pk] = isChecked;
    dispatch(setCheckedBoxes(_checkedItems));

    if (!isChecked) {
      // allChecksOutsideRef.current = true;
      checkAllChange(e, true);
    }
  };
  const getStatus = (id: number) => {
    if (!voca) {
      return '';
    }

    const item = voca.results.find(v => v.id === id);
    if (item) {
      return item.title;
    }

    return '';
  };

  const checkAllChange = (e, isSkipping) => {
    const isChecked = e.target.checked;
    const _checkedItems = {};
    setAllChecked(isChecked);
    if (isSkipping) {
      return;
    }    
    rows.forEach(row => {
      _checkedItems[row.pk] = isChecked;
    });
    dispatch(setCheckedBoxes(_checkedItems));
  };

  const applySorting = sortBy => {
    let _params1: any = { ..._params };
    delete _params1['sort_by'];
    if (sortBy) {
      _params1['sort_by'] = sortBy;
    }
    setParams(_params1);
  };
  return (
    <div>
      <>
        {isFetching && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) }
        <GridBase>
          <HeaderCell>
            <input type="checkbox" checked={allChecked} value={`${allChecked}`} onChange={checkAllChange} />
          </HeaderCell>
          <HeaderCell>
            <SortingHeader applySorting={applySorting} field="name" name="Document Name" />
          </HeaderCell>
          <HeaderCell>
            <SortingHeader applySorting={applySorting} field="admin_file_name" name="Admin File" />
          </HeaderCell>
          <HeaderCell>
            <SortingHeader applySorting={applySorting} field="client_files" name="Client File" />
          </HeaderCell>
          <HeaderCell>
            {someDocumentsSelected(mcheckedBoxes) ? (
              <StatusDropdown voca={voca} refetch={refetch} />
            ) : (
              <SortingHeader applySorting={applySorting} field="status__name" name="Status" />
            )}
          </HeaderCell>
          <HeaderCell>
            <SortingHeader applySorting={applySorting} field="last_comment" name="Last Comment" />
          </HeaderCell>
          <HeaderCell>Actions</HeaderCell>
        </GridBase>
        <RowBase>
          <TableCell></TableCell>
          <TableCell>
            <SearchInput name="document_name" />
          </TableCell>
          <TableCell>
            <SearchInput name="admin_file_name" />
          </TableCell>
          <TableCell>
            <SearchInput name="client_file_name" />
          </TableCell>
          <TableCell>
            <SearchInput name="activity_status" />
          </TableCell>
          <TableCell>
            <SearchInput name="lastComment" />
          </TableCell>
          <TableCell></TableCell>
        </RowBase>

      
        <Rows>
            {rows.map((row, index) => {
              return (
                <RowBase key={index}>
                  <TableCell>
                    <input type="checkbox" checked={mcheckedBoxes[row.pk]} onChange={e => onChange(e, row.pk)} />
                  </TableCell>
                  <TableCell>
                    <FlexCol>{row.name}</FlexCol>
                  </TableCell>
                  <TableCell>
                    <FlexCol>
                      {row.adminFiles &&
                        row.adminFiles.map((file, index) => {
                          return <FileEntry key={index} file={file} row={row} fileType="admin" refetch={refetch}/>;
                        })}
                    </FlexCol>
                  </TableCell>
                  <TableCell>
                    <ClientFiles>
                    {row.clientFiles &&
                        row.clientFiles.map((file, index) => {
                          return <FileEntry key={index} file={file} row={row} fileType="client" refetch={refetch}/>;
                        })}
                    </ClientFiles>
                  </TableCell>
                  <TableCell>
                    <Status>{getStatus(row.status)}</Status>
                  </TableCell>
                  <TableCell>{row.lastComment.replace(/<\/?p[^>]*>/g, '')}</TableCell>
                  <TableCell>
                    <DocumentActions row={row} handleEdit={handleEdit} refetch={refetch} />
                  </TableCell>
                </RowBase>
              );
            })}
          </Rows>
      </>

      <Pagination>
        <PaginationMobile showEverywhere={true} />
      </Pagination>
    </div>
  );
};

export default DocumentsTable;

const GridBase = styled.div`
  display: grid;
  grid-template-columns: 55px repeat(3, 1fr) 125px 1fr 100px;
  width: 100%;
  padding-right: 21px;
  text-align: left;
`;

const RowBase = styled(GridBase)`
  border: 1px solid var(--border);
  border-radius: 6px;
  border-bottom: 4px double var(--border);
  background-color: var(--defaultTableCell);
`;

const Rows = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const ClientFiles = styled.div`
  padding-left: 11px;
`;

const Status = styled.div`
  padding-left: 10px;
`;
const HeaderCell = styled.div`
  font-weight: bold;
  padding: 15px;
  border-radius: 6px;
  color: var(--defaultTableCellTitle);
  background-color: var(--defaultTableCell);
  font-size: 0.875rem;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

const TableCell = styled.div`
  margin: 0;
  text-align: left;
  padding: 18px 16px;
  border-bottom: 1px solid var(--border);
  overflow: auto;
  color: var(--mainText);
  font-size: 0.875rem;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; ;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;
