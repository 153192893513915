import { useEffect, useRef, useState } from 'react';
import { getVocabulariesDataList } from '../../../api/vocabulary';
import { GET_ALL_QUERY_PARAMS, InspectionStatuses } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { VocabularyItem, setSchedules, setVocabulary } from '../../../redux/slices/generalSlice';
import { RootState } from '../../../redux/root';
import { getFranchiseList } from '../../../api/franchise';
import { getReportManagers } from '../../../api/reports';
import { setFranchises, setManagers, setProjects } from '../../../redux/slices/projectSlice';
import { getClientDocuments, getClientPendingProjects, getContactProjects } from '../../../api/projects';
import { normalizeProjectPermits } from '../utils';
import { getInspectionsByClient } from '../../../api/inspections';
import { DocumentConditionItem, FeeItemType, InspectionItemType, PermitFeeItem, PermitInspectionsType, PermitTicketItem, TicketItem } from '../types';
import { setPermitInspectionsList } from '../../../redux/slices/inspectionsSlice';
import { setAllPermitsWithDocs } from '../../../redux/slices/documentsSlice';
import { getScheduling } from '../../../api/scheduling';
import { getAllConditions } from '../../../api/conditions';
import { setConditions } from '../../../redux/slices/conditionsSlice';
import { getAllInvoices } from '../../../api/fees';
import { getAllTickets } from '../../../api/tickets';
import { setPermitTicketsList } from '../../../redux/slices/ticketsSlice';
import { setClientProjecPermitList } from '../../../redux/slices/feesSlice';

const useGetNewProjectData = () => {
  const dispatch = useDispatch();
  const vocabularies = useSelector((state: RootState) => state.general.vocabulary);

  const { franchises, managers, projects } = useSelector((s: RootState) => s.projectsSlice);
  const { inspections } = useSelector((s: RootState) => s.inspections);
  const { AllPermitsWithDocs } = useSelector((s: RootState) => s.documents);
  const schedules = useSelector((s: RootState) => s.general.schedules);
  const contact_id = useSelector((s: RootState) => s.userAuth.info.id);
  const AllPermitsWithConditions = useSelector((s: RootState) => s.conditions.AllPermitsWithConditions);
  const invoicesList = useSelector((s: RootState) => s.fees.inspectionInvoicesList);
  const permitTicketsList = useSelector((s: RootState) => s.tickets.permitTicketsList);

  useEffect(() => {
    loadVocabulary();
    loadFranchise();
    loadManagers();
    loadInspections();
    loadAllDocuments();
    loadScheduling();
    loadConditions();
    loadInvoices();
    loadTickets();
  }, []);

  const loadTickets = () => {
    if (permitTicketsList.length === 0) {
      const getData = async () => {
        const payload = await getAllTickets(contact_id);
        const data: PermitTicketItem[] = payload.data.map((item: TicketItem) => {
          return {
            namePermitName: `${item.projectName}/${item.permitName}`,
            permitId: Number(item.permitId),
            address: item.projectAddress,
            status: item.permitStatus,
            quantity: Number(item.ticketQuantity),
            tickets: [],
          };
        });
        dispatch(setPermitTicketsList(data));
      };
      getData();
    }
  };

  const loadInvoices = () => {
    if (invoicesList.length === 0) {
      const getData = async () => {
        const {data} = await getAllInvoices(contact_id);
        const projectsPermit = data.reduce((prjArr,prj) => {
          const permits = prj.permits.forEach((permit)=>{
            const _permit = {
              ...permit,
              invoices: permit.invoices.map( invoice => {
                const _invoice = {
                  ...invoice,
                  status: invoice.paidAt === null ? 'Pending' : 'Paid',
                  paidAt: invoice.paidAt === null ? '' : invoice.paidAt,
                };
                return _invoice;
              })
            } 
            const _prjPermit = {
              ...prj,
              permit: _permit,
              projectPermit: `${prj.projectName} / ${_permit.permitName}`,
              namePermitName: `${prj.projectName} / ${_permit.permitName}`,
              address: _permit.permitAddress,
              franchiseId: _permit.franchiseId,
              quantity: _permit.invoices.filter(i => i.status === 'Pending').length,
            }
            prjArr.push(_prjPermit);
          });
          return prjArr;
        },[])
        dispatch(setClientProjecPermitList(projectsPermit));
      };
      getData();
    }
  };
  const loadConditions = () => {
    if (AllPermitsWithConditions.length === 0) {
      const getData = async () => {
        const payload = await getAllConditions();
        const data: DocumentConditionItem[] = payload.data;

        const _data = payload.data.map((item: any) => ({
          address: item.projectAddress,
          permitId: item.permitId,
          namePermitName: `${item.projectName}/${item.permitName}`,
          quantity: item.quantity,
          status: item.permitStatus,
        }));
        dispatch(setConditions(_data));
      };
      getData();
    }
  };

  const loadScheduling = () => {
    if (schedules.length === 0) {
      const getData = async () => {
        const params = {
          offset: 0,
          is_active: true,
          is_deleted: false,
          limit: 100000000,
        };
        const payload = await getScheduling(params);
        dispatch(setSchedules(payload.data.results));
      };
      getData();
    }
  };
  const loadAllDocuments = () => {
    if (AllPermitsWithDocs.length === 0) {
      const getData = async () => {
        const payload = await getClientDocuments(contact_id);
        const _data = payload.data.map((item: any) => ({
          address: item.address,
          permitId: item.permitId,
          namePermitName: `${item.projectName}/${item.permitName}`,
          quantity: item.quantity,
          status: item.permitStatus,
        }));
        dispatch(setAllPermitsWithDocs(_data));
      };
      getData();
    }
  };

  const loadInspections = () => {
    if (inspections.length === 0) {
      const load = async () => {
        const payload = await getInspectionsByClient(contact_id);
        let previousPermit: number = -1;
        const permitInspections: PermitInspectionsType[] = [];
        const sortedData = payload.data.sort((a,b)=>{
          if(a.permit > b.permit) return 1;
          if(a.permit < b.permit) return -1;
          return 0;
        })
        sortedData.forEach((item: InspectionItemType) => {
          if (item.permit !== previousPermit) {
            const permit: PermitInspectionsType = {
              permitId: item.permit,
              namePermitName: `${item.projectName}/${item.permitName}`,
              address: item.projectAddress,
              inspections: [item],
              status: item.permitStatus,
              quantity: 1,
            };
            permitInspections.push(permit);
            previousPermit = item.permit;
          } else {
            const permitItem = permitInspections.find((p: PermitInspectionsType) => p.permitId === item.permit);
            permitItem.inspections.push(item);
            permitItem.quantity = permitItem.quantity + 1;
          }
        });

        const _permitInspectionsSorted = permitInspections.reduce((permitArr, item) => {
          if (item.status === InspectionStatuses.NOT_SCHEDULED) {
            permitArr.unshift(item);
          } else {
            permitArr.push(item);
          }
          return permitArr;
        }, []);
        dispatch(setPermitInspectionsList(_permitInspectionsSorted));
      };
      load();
    }
  };
  const loadProjects = async (vocabularies: VocabularyItem[], refresh?: boolean) => {
    if (projects.length === 0 || refresh) {
      const getProjects = async () => {
        const payload = await getContactProjects({
          contact_id,
        });

        const pending = await getClientPendingProjects(contact_id);
        //@ts-ignore
        const projectPermits = normalizeProjectPermits(payload.data, pending.data.results, vocabularies);

        //@ts-ignore
        dispatch(setProjects(projectPermits));
      };
      getProjects();
    }
  };

  const loadVocabulary = async () => {
    if (vocabularies.length === 0) {
      const payload = await getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'title',
      });
      const vocabularyItems = payload.data.results;
      dispatch(setVocabulary(vocabularyItems));
      //@ts-ignore
      loadProjects(vocabularyItems, true);
    }
  };

  const loadFranchise = async () => {
    if (franchises.length === 0) {
      const payload = await getFranchiseList({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      });
      const franchiseItems = payload.data.results;
      dispatch(setFranchises(franchiseItems));
    }
  };

  const loadManagers = async () => {
    if (managers.length === 0) {
      const payload = await getReportManagers();
      const managers = payload.data;
      dispatch(setManagers(managers));
    }
  };


  return { loadProjects };
};


export default useGetNewProjectData;

