import axios from 'axios';
import { api } from './api';
import { PaginatedRequest } from '../types';
import {
  onResponseErrorInterceptor,
  onResponseInterceptor,
} from './interceptors';
import { getAccessToken } from '../auth';
import { DocumentType } from './document';
import { formatToFormData } from '../utils';
import { VocabularyDataType } from './vocabulary';

export const getPermitDocuments = (
  params?: PaginatedRequest,
  permit?: string,
) =>
  api.request({
    method: 'GET',
    url: '/project_documents/',
    params: {
      ...params,
      permit,
    },
  });

// Get reviewer comments data
export const getReviewerComments = (reviewId) =>
  api.request({
    method: 'GET',
    url: `/project_activities/review_comments/${reviewId}/get_review_comments/`,
  });

export const updateClientReviewComments = (data) => {
  const fd:FormData = new FormData();
  Object.keys(data).forEach((key) => {
    fd.append(key, data[key]);
  });
  
  // return api.patch(`/project_activities/review_comments/${data.id}/`,fd);

  return api.request({
  method: 'PATCH',
  url: `/project_activities/review_comments/${data.id}/`,
  data
});
}


export const createPermitDocument = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  const formData = new FormData();
  formData.append('permit', data.permit);
  formData.append('name', data.name);
  formData.append('status', data.status);
  formData.append('isClientAvailable','true');
  if(data.adminFile){
    for (let i = 0; i < data.adminFile.length; i++) {
      formData.append(`admin_file`, data.adminFile[i]);
    }
  }

  if(data.clientFile){
    for (let i = 0; i < Array.from(data.clientFile).length; i++) {
      formData.append(`client_file`, data.clientFile[i]);
    }
  }

  if(data.correctionFile){
    formData.append(`correction_file`, data.correctionFile);
  }

  return axios.request<DocumentType>({
    method: 'POST',
    url: '/api/v1/project_documents/',
    data: formData,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateIsClientAvailable = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  const fd = new FormData();
  // eslint-disable-next-line no-plusplus
  fd.append('is_client_available', data?.isClientAvailable)

  return axios.request({
    method: 'PATCH',
    url: `/api/v1/project_documents/${data?.id}/`,
    data: fd,
  });
}

export const downloadAllPermitClientDocuments = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  const fd = new FormData();
  // eslint-disable-next-line no-plusplus
  // for (let i = 0; i < data.length; i++) {
  //   fd.append('documents', data[i]);
  // }

  // fd.append('client_file_id', data?.ids)
  fd.append('client', data?.client)
  fd.append('documents', data?.docPk)

  return axios.request({
    method: 'POST',
    url: '/api/v1/project_documents/download/',
    data: fd,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ContentType: 'application/zip',
    },
  });
};

export const downloadAllPermitDocumentsCommaSeparatedIds = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  const fd = new FormData();
  fd.append('documents', data);

  return axios.request({
    method: 'POST',
    url: '/api/v1/project_documents/download/',
    data: fd,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ContentType: 'application/zip',
    },
  });
};


export const downloadAllPermitDocuments = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  const fd = new FormData();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data.length; i++) {
    fd.append('documents', data[i]);
  }

  return axios.request({
    method: 'POST',
    url: '/api/v1/project_documents/download/',
    data: fd,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ContentType: 'application/zip',
    },
  });
};

export const downloadSinglePermitDocuments = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  const fd = new FormData();
  if (data?.admin === true) {
    fd.append('admin', data?.admin)
  }
  if (data?.client === true) {
    fd.append('client', data?.client)
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data?.docPk?.length; i++) {
    fd.append('documents', data?.docPk[i]);
  }

  return axios.request({
    method: 'POST',
    url: '/api/v1/project_documents/download/',
    data: fd,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ContentType: 'application/zip',
    },
  });
};

export const downloadSinglePermitDocumentWithFullName = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  const fd = new FormData();
  fd.append(data?.apiId, data?.fileId);
  fd.append('documents', data?.documentPk);

  return axios.request({
    method: 'POST',
    url: '/api/v1/project_documents/download/',
    data: fd,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ContentType: 'application/zip',
    },
  });
};

export const updatePermitDocument = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  const formData = new FormData();
  formData.append('status', data.status);
  formData.append('isCompleted',data.isCompleted);
  if(data.adminFile){
    for (let i = 0; i < Array.from(data.adminFile).length; i++) {
      formData.append(`admin_file`, data.adminFile[i]);
    }
  }

  if(data.clientFile){
    for (let i = 0; i < Array.from(data.clientFile).length; i++) {
      formData.append(`client_file`, data.clientFile[i]);
    }
  }

  return axios.request<DocumentType>({
    method: 'PATCH',
    url: `/api/v1/project_documents/${data.id}/`,
    data: formData,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getPermitDocumentComments = (
  doc: string,
  params?: PaginatedRequest,

) =>
  api.request({
    method: 'GET',
    url: `/project_documents/${doc}/comments/`,
    params: {
      ...params,
      doc,
    },
  });

export const getProjectDocumentFiles = (
  params: PaginatedRequest,
  doc: string,
) =>
  api.request({
    method: 'GET',
    url: `/project_documents/${doc}/`,
    params,
  });

export const createPermitDocumentComment = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );

  const body = formatToFormData({
    text: data.text,
  });
  return axios.request<DocumentType>({
    method: 'POST',
    url: `/api/v1/project_documents/${data.id}/comments/`,
    data: body,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const deletePermitDocument = (id: number) =>
  api.request<VocabularyDataType>({
    method: 'DELETE',
    url: `/project_documents/${id}/`,
  });

export const sendPermitDocuments = ({
  documents,
  contacts,
}: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );

  const fd = new FormData();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < documents.length; i++) {
    fd.append('documents', documents[i]);
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < contacts.length; i++) {
    fd.append('contacts', contacts[i]);
  }

  return axios.request<DocumentType>({
    method: 'POST',
    url: `/api/v1/project_documents/send_to_email/`,
    data: fd,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const updatePermitDocumentComment = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );

  const body = formatToFormData({
    isResolved: data.isResolved
  });
  return axios.request<DocumentType>({
    method: 'PATCH',
    url: `/api/v1/project_documents/${data.id}/comments/${data.rowid}/`,
    data: body,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};


