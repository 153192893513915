import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import DetailsHeaderComponent from '../../Common/DetailsHeaderComponent';
import { CustomParams, PermitInspectionsType } from '../../../types';
import styled from 'styled-components';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TableRow from '../../Common/Table/TableRow';
import EditInspectionPopup from './EditInspectionPopup';
import { setSelectedInspection } from '../../../../../redux/slices/inspectionsSlice';
import { sortTableHeader } from '../../../utils';
import { setBottomDrawerOpened } from '../../../../../redux/slices/generalSlice';
import { Logo_Sky } from '../../../../../constants/menu';
import TableHeaderCell from '../../Common/Table/Header/TableHeaderCell';
import { InspectionStatuses } from '../../../../../constants';
import { useGetPermitConditionsQuery } from '../../../../../redux/rtkQuery/apiSlice';

type VisibleInspectionType = {
  id: number;
  code: string;
  description: string;
  inspectionDate: string;
  statusName: string;
  report: string | null;
};
export type ScreenType = 'Request' | 'View';
const PermitInspectionsDetails = () => {
  const selectedPermit: PermitInspectionsType = useSelector((s: RootState) => s.inspections.selectedPermit);
  const [inspections, setInspections] = useState<VisibleInspectionType[]>([]);
  const [updatedStatusId, setUpdatedStatusId] = useState<number>(-1);
  const [params, setParams] = useState(null);
  const [disableFromHold, setDisableFromHold] = useState<boolean>(false);
  const { data: conditions } = useGetPermitConditionsQuery(params, { skip: params === null });
  const { tableSortingDirection, tableSortingKey } = useSelector((s: RootState) => s.general);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedPermit.inspections) {
      const visibleInspections = getVisibleInspections();
      setInspections(visibleInspections);

      if (selectedPermit.status === 'Hold') {
        setDisableFromHold(true);
      } else {
        const _params: CustomParams = {
          is_deleted: false,
          limit: 100,
          offset: 0,
          permit_id: selectedPermit.permitId,
          event_type: 'Activity',
          activity_type_name: 'Conditions',
        };
        setParams(_params);
        if (conditions) {
          const onHold = conditions.results.filter(f => {
            return f.activityTitleName === 'Inspection Hold' || f.activityTitleName === 'Permit Hold';
          });
          setDisableFromHold(onHold.length > 0);
        }
      }
    }
  }, [selectedPermit, conditions]);
  useEffect(() => {
    const _inspections = sortTableHeader(tableSortingDirection, tableSortingKey, getVisibleInspections());
    setInspections(_inspections);
  }, [tableSortingDirection, tableSortingKey]);
  const colsTemplate = '1fr 1fr 1fr 1fr 190px';
  const getInspectionTokens = (token: string) => {
    if (!token) {
      return {
        description: '',
        code: '',
      };
    }
    const tokenArr = token.split(' ');
    return {
      description: token.substring(tokenArr[0].length),
      code: tokenArr[0],
    };
  };
  const getVisibleInspections = () => {
    if (selectedPermit.inspections) {
      return selectedPermit.inspections.map(insp => {
        const { code, description } = getInspectionTokens(insp.activityTypeName);
        const retValue = {
          id: insp.id,
          code: code,
          description: description,
          inspectionDate: insp.inspectionDate,
          statusName: insp.statusName,
          report: insp.report,
        };
        if (insp.id === updatedStatusId) {
          setUpdatedStatusId(-1);
          return {
            ...retValue,
            statusName: InspectionStatuses.READY_FOR_SCHEDULING,
          };
        }
        return retValue;
      });
    }
    return [];
  };

  const isEnabled = (item: VisibleInspectionType, linkType: ScreenType): boolean => {
    const status = item.statusName;
    switch (linkType) {
      case 'View':
        const hasReport = item.report !== null;
        if (hasReport) {
          return true;
        }
        const statusCheck = ['Cancelled', 'Failed', 'Not Applicable', 'Partial Pass', 'Pass'].indexOf(status) > -1;

        return statusCheck && hasReport;
      case 'Request':
        return [InspectionStatuses.NOT_SCHEDULED, InspectionStatuses.SCHEDULED].indexOf(status) > -1;
    }
    return false;
  };

  const getReportUrl = (item: VisibleInspectionType) => {
    if (item.report) {
      const url = decodeURIComponent(item.report.replace(/\/media\/https%3A/, 'https:/'));
      return url;
    }
    return '';
  };

  const onRequest = (item: VisibleInspectionType) => {
    const disabled = !isEnabled(item, 'Request');
    if (disabled) {
      return;
    }

    const fullItem = selectedPermit.inspections.find(i => i.id === item.id);
    dispatch(setSelectedInspection(fullItem));
    dispatch(setBottomDrawerOpened(true));
  };

  const getStatusName = (
    item: VisibleInspectionType,
  ):
    | string
    | number
    | boolean
    | {}
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactNodeArray
    | React.ReactPortal => {
    if (item.statusName === InspectionStatuses.READY_FOR_SCHEDULING) {
      return 'Inspector to be assigned';
    }
    return item.statusName;
  };

  return (
    <Wrapper>
      <Header>Inspection List</Header>
      <DetailsHeaderComponent names={selectedPermit.namePermitName} address={selectedPermit.address} />
      <TableWrapper colsTemplate={colsTemplate}>
        <TableHeaderCell label="Inspection Code" itemName="" hideFilter={true} sortingKey="code" theKey="code" />

        <TableHeaderCell label="Description" itemName="" hideFilter={true} sortingKey="description" theKey="description" />

        <TableHeaderCell label="Inspection Date" itemName="" hideFilter={true} sortingKey="inspectionDate" theKey="inspectionDate" />

        <TableHeaderCell label="Status" itemName="" hideFilter={true} sortingKey="statusName" theKey="statusName" />

        <TableHeaderCell label="Actions" itemName="" hideFilter={true} />
      </TableWrapper>
      <RecordsWrapper>
        {inspections.map((item: VisibleInspectionType, index: number) => {
          return (
            <TableRow key={index} index={index} gridTemplateCols={colsTemplate} status="">
              <Cell>
                <CellLabel>Inspection Code:</CellLabel>
                {item.code}
              </Cell>
              <Cell>
                <CellLabel>Description:</CellLabel>
                {item.description}
              </Cell>
              <Cell>
                <CellLabel>Inspection Date:</CellLabel>
                {item.inspectionDate}
              </Cell>
              <Cell>
                <CellLabel>Status:</CellLabel>
                {getStatusName(item)}
              </Cell>
              <Actions>
                <IconView isBlue={true} disabled={!isEnabled(item, 'Request')} onClick={() => onRequest(item)}>
                  <AssignmentLateIcon />
                  <ActionTitle>Request</ActionTitle>
                </IconView>
                <IconView isBlue={false} disabled={!isEnabled(item, 'View')}>
                  <VisibilityIcon />
                  <ActionTitle>
                    {isEnabled(item, 'View') ? (
                      <a href={getReportUrl(item)} target="_blank">
                        View{' '}
                      </a>
                    ) : (
                      <span>View</span>
                    )}
                  </ActionTitle>
                </IconView>
              </Actions>
            </TableRow>
          );
        })}
      </RecordsWrapper>

      <EditInspectionPopup disableFromHold={disableFromHold} updateNewStatus={setUpdatedStatusId} />
    </Wrapper>
  );
};

export default PermitInspectionsDetails;

const Wrapper = styled.div`
  margin: 22px;
`;

const RecordsWrapper = styled.div`
  max-height: 44svh;
  overflow: auto;
`;

const Header = styled.div`
  font-size: 29px;
  margin-bottom: 21px;
  color: ${Logo_Sky};
`;

const TableWrapper = styled.div<{ colsTemplate: string }>`
  display: grid;
  grid-template-columns: ${({ colsTemplate }) => colsTemplate};
  font-size: 20px;
  font-weight: bold;
  height: 41px;
  overflow: hidden;
  margin-top: 10px;
  @media only screen and (max-width: 540px) {
    visibility: hidden;
  }
`;

const HeaderCell = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${Logo_Sky};
`;

const IconView = styled.div<{ disabled: boolean; isBlue: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 88px;
  margin: 8px;
  color: ${({ disabled, isBlue }) => {
    const _color = isBlue ? Logo_Sky : 'green';
    return disabled ? '#ccc' : _color;
  }};
  cursor: pointer;
  &:hover {
    background-color: #ccc;
  }
`;

const ActionTitle = styled.div`
  width: 60px;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid grey;
  padding-left: 10px;
  @media only screen and (max-width: 540px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 17px;
  }
`;

const CellLabel = styled.div`
  display: none;
  @media only screen and (max-width: 540px) {
    font-size: 12px;
    font-weight: bold;
    display: inherit;
  }
`;

const Actions = styled.div`
  @media only screen and (max-width: 540px) {
    grid-column: 3 / 4;
  }
`;
