import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Logo_Sky } from '../../../../../constants/menu';

export type Props = {
  label: string;
  value: string;
  isText?: boolean;
  invalidMessage?: string;
  header2?: string;
};

const OtherField: FC<Props & {}> = ({ label, value, isText = false, invalidMessage, header2, children }) => {
  const [readOnly, setReadOnly] = useState<boolean>(children === undefined);
  const error = invalidMessage !== null && invalidMessage !== undefined;
  return (
    <Wrapper>
      <Label error={error}>{label}</Label>
      {header2 && <Disclaimer>{header2}</Disclaimer>}
      <InputWrapper error={error} readOnly={readOnly}>
        {children}
        {!children && <Value>{value}</Value>}
        {error && <Error isText={isText}>{invalidMessage}</Error>}
      </InputWrapper>
    </Wrapper>
  );
};

export default OtherField;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
`;

const Disclaimer = styled.div`
  color: ${Logo_Sky};
  font-size: 15px;
  font-style: italic;
  position: relative;
  top: -1px;
  padding: 5px;
  border-radius: 6px;
`;

const Error = styled.div<{ isText: boolean }>`
  color: red;
  position: relative;
  top: ${({ isText }) => (isText ? '-14px' : '0')};
`;

const Label = styled.div<{ error: boolean }>`
  font-size: 18px;
  color: ${({ error }) => (error ? 'red' : '#316B99')};
`;

const InputWrapper = styled.div<{ readOnly: boolean; error?: boolean }>`
  border: ${({ error }) => (error ? '1px solid red' : ' 1px solid grey')};
  background-color: ${({ readOnly }) => (readOnly ? '#efefef' : '#fff')};
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  input {
    border: none;
  }
  .react-datepicker-ignore-onclickoutside {
    outline: none;
    border: none;
    &:focus-visible {
      outline: none;
      border: none;
    }
  }
`;

const Value = styled.div`
  color: grey;
  height: 20px;
`;
