import React, { FC, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import styled from 'styled-components';
import { ROUTES } from '../../constants/routes';

type Props = {
  reviewsRows: any[];
};
const ReviewsTable: FC<Props> = ({ reviewsRows }) => {
  const history = useHistory();
  const onClick = row => {
    const path = generatePath(ROUTES.PERMIT, {
      projectId: row.projectId,
      permitId: row.permit,
    });
    history.push(path);
  };

  return (
    <Wrapper>
      <Row>
        <HeaderCell>
          <Left>Project - Permit</Left>
        </HeaderCell>
        <HeaderCell>
          <Left>Activit Title</Left>
        </HeaderCell>
        <HeaderCell>
          <Left>Follow up</Left>
        </HeaderCell>
      </Row>
      <RowsWrapper>
        {reviewsRows.length === 0 && <NoMatching>No matching records found</NoMatching>}
        {reviewsRows.length > 0 &&
          reviewsRows.map((review, index) => {
            return (
              <Row key={index}>
                <Cell>
                  <Left>
                    <Underlined onClick={e => onClick(review)}>{`${review.projectName} - ${review.permitName}`}</Underlined>
                  </Left>
                </Cell>
                <Cell>
                  <Left>{review.activityTitleName}</Left>
                </Cell>
                <Cell>
                  <Left>{review.followUp}</Left>
                </Cell>
              </Row>
            );
          })}
      </RowsWrapper>
    </Wrapper>
  );
};

export default ReviewsTable;

const Underlined = styled.div`
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
`;
const Left = styled.div`
  width: 100%;
  text-align: left;
  padding-left: 20px;
`;
const NoMatching = styled.div`
  color: var(--defaultTableCellTitle);
  width: 100%;
  text-align: center;
  padding-top: 27px;
`;

const Wrapper = styled.div`
  font-size: 13px;
  text-align: left;
`;

const RowsWrapper = styled.div`
  height: 150px;
  overflow: auto;
`;

const HeaderCell = styled.div`
  font-weight: bold;
  background-color: #fcf9fc;
  color: var(--defaultTableCellTitle);
  text-align: left;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: center;
`;

const Cell = styled.div`
  text-align: left;
  background-color: #fff;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  justify-content: center;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid var(--border);
  border-right: 1px solid var(--border);
  border-left: 1px solid var(--border);
  border-radius: 6px;
`;
