import React, { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import styled from 'styled-components';
import history from '../../../history';
import Airplay from '@material-ui/icons/Airplay';
import { getSingleFormReport } from '../../../api/reports';
import { Button } from '@material-ui/core';
import { setPdfReport } from '../../../redux/slices/inspectionsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../atoms';
import { breakLines } from '../../../modules/clientModule/utils';
import ModalInfo from '../../../molecules/ModalInfo';
import { ROUTES } from '../../../constants/routes';
import { RootState } from '../../../redux/root';
import { getSignature } from '../../../api/userAuth';
import { InspectionStatuses } from '../../../constants';
import { format } from 'date-fns';

const PDF = ({ initialValues, setInspectionReport, selectedStatusValue, getValues, watch, setPdfObject, setReportPdf }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const dispatch = useDispatch();
  const [pdf, setPdf] = useState<any>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [signature, setSignature] = useState<string>('');
  const userId = useSelector((s: RootState) => s.userAuth.info.id);

  const inspectionDate = watch('inspectionDate');
  const inspectionType = watch('inspectionType');
  const information = watch('information');

  useEffect(() => {
    if (userId) {
      const requestSignature = async () => {
        const _signature = await getSignature(userId);
        setSignature(_signature);
      };
      requestSignature();
    }
  }, [userId]);
  useEffect(() => {
    isDisabled();
  }, [inspectionDate, inspectionType, selectedStatusValue, information]);

  const onSendReport = () => {
    if(!signature){
      setOpen(true);
    }
    createReport(signature);
  };
  const createReport = signUrl => {
    const createInspectionReport = async () => {
      if (!signUrl) {
        return;
      }
      setShowLoader(true);
      const result = await getSingleFormReport({
        date: initialValues.inspectionDate,
        inspector: initialValues.inspector,
        jurisdiction: initialValues.projectJurisdiction,
        inspectionId: initialValues.id,
        statusName: initialValues.statusName,
        signature: signUrl,
        information: getValues('information') === '' ? null : breakLines(getValues('information')),
      });
      setShowLoader(false);
      const _pdf = new jsPDF('p', 'mm', 'a4');
      setPdf(_pdf);
      setPdfObject(_pdf);
      _pdf.addImage(result.data[0].image, 'JPEG', 0, 0, 220, 290);
      const output = _pdf.output('blob');
      const formattedInspectionDate = format(new Date(initialValues.inspectionDate),'yyyyMMdd');
      const permitNumber = initialValues.permitNumber || '';
      const inspectionCode = initialValues.inspectionType? String(initialValues.inspectionType).match(/^\d+/)?.[0] || '' : '';
      const _fileName = `${formattedInspectionDate} ${permitNumber} ${inspectionCode} ${initialValues.id}.pdf`.trim();
      setFileName(_fileName);
      const pdfFile = new File([output], _fileName, { type: 'application/pdf' });
      setInspectionReport(pdfFile);
      setReportPdf(pdfFile);

      dispatch(setPdfReport(_fileName));
    };
    createInspectionReport();
    return false;
  };
  const downloadReport = () => {
    pdf.save(fileName);
  };

  const isDisabled = () => {
    if (initialValues?.isCompleted || selectedStatusValue?.name === InspectionStatuses.SCHEDULED) {
      setButtonDisabled(true);
      return;
    }
    const inspectionDate = initialValues.inspectionDate;
    if (!inspectionDate) {
      setButtonDisabled(true);
      return;
    }

    const inspectionType = getValues('inspectionType');
    if (!inspectionType || !isNaN(inspectionType)) {
      setButtonDisabled(true);
      return;
    }
    setButtonDisabled(false);
  };

  const onClose = () => {
    setOpen(false);
    history.push(ROUTES.ACCOUNT);
  };
  return (
    <Wrapper>
      {showLoader && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <IconWrapper onClick={downloadReport}>{fileName && !showLoader && <Airplay />}</IconWrapper>
      <Button
        style={{
          margin: 0,
          borderRadius: '6px',
        }}
        disabled={buttonDisabled}
        variant="contained"
        component="label"
        color="primary"
        onClick={onSendReport}
      >
        Create Report
      </Button>
      {/* {open && <SignaturePad createReport={createReport} setOpen={setOpen} />} */}
      {open && <ModalInfo onClose={onClose} title="Signature is Required" errorMsg="Signature is missing - please update" />}
    </Wrapper>
  );
};

export default PDF;

const Wrapper = styled.div`
  visibility: visible;
  font-size: 2mm;
  display: flex;
  align-items: center;
  width: 185px;
  gap: 7px;
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 24px;
`;

const LoaderWrapper = styled.div`
  width: 0px;
  height: 24px;
  zoom: 0.5;
  position: relative;
  top: -15px;
`;
