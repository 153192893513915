import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Pagination } from '../../modules/clientModule/components/Common/Table/types';
import PaginationMobile from '../Responsive/PaginationMobile';
import { useSelector } from 'react-redux';
import { useGetInspectionDocumentFileResubmitFlagQuery, useGetVocabularyQuery, useResubmitDocumentFileMutation } from '../../redux/rtkQuery/apiSlice';
import { RootState } from '../../redux/root';
import { CustomParams } from '../../modules/clientModule/types';
import { DocStatus, InspectionStatuses } from '../../constants';
import { getPermitDocuments } from '../../api/projectDocuments';

const DocumentsDetailsFileList = ({ row, inspectorGetPermitDocuments }) => {
  const [params, setParams] = useState<any>(null);
  const [params1, setParams1] = useState<any>(null);
  const [resubmitDocument] = useResubmitDocumentFileMutation();
  const {data: checkboxValues, refetch,isUninitialized} = useGetInspectionDocumentFileResubmitFlagQuery(params1,{skip: params1 === null});
  const { pageCount, pageIndex, pageSize } = useSelector((s: RootState) => s.pagination);
  const info = useSelector((s:RootState) => s.userAuth.info);
  const filters = useSelector((s: RootState) => s.filters.filters);
  const [rows, setRows] = useState([]);
  const [checks,setChecks] = useState({});

  const load = async () => {
    const res = await inspectorGetPermitDocuments({ limit: 100, offset: 0 });
    setRows(res.data.results); 
  };
  useEffect(() => { 
    load();
  }, []);
  const _params: CustomParams = {
    is_deleted: false,
    limit: pageSize,
    offset: pageIndex * pageSize,
    permit_id: row.permitId,
    event_type: 'Activity',
    activity_type_name: 'Document',
  };

  useEffect(() => {
    if(info){
      setParams1({
        inspector: info.id,
        review_activity: row.id,
      })
      if(!isUninitialized){
        refetch();
      }
    }    
    if(checkboxValues && checkboxValues.length > 0){
      const _checks = {};
      checkboxValues.forEach(r => {
        _checks[r.document] = r.resubmit;
      })
      setChecks(_checks);
    }
  }, [info,checkboxValues])
  useEffect(() => {
    let _params1: any = { ..._params, limit: pageSize };
    if (filters) {
      filters.forEach(f => {
        _params1[f.filterName] = f.filterValue;
      });
    }

    setParams(_params1);
  }, [pageSize, pageIndex, pageCount, filters]);

  const onChange = async (e, _row) => {
    const isChecked = e.target.checked;
    const _checks = {...checks};
    _checks[_row.pk] = isChecked;
    setChecks(_checks);
    const payload = {
      inspector: info.id,
      review_activity: row.id,
      document: _row.pk,
      status: isChecked,
    }
    await resubmitDocument(payload);
  };

  return (
    <div>
      <>
        <GridBase>
          <HeaderCell>Document Name</HeaderCell>
          <HeaderCell>File</HeaderCell>
          <HeaderCell>Resubmit</HeaderCell>
        </GridBase>

        <Rows>
          {rows.map((row, index) => {
            return (
              <RowBase key={index}>
                <TableCell>
                  <FlexCol>{row.name}</FlexCol>
                </TableCell>
                <TableCell>
                  <FlexCol>
                    {row.clientFiles &&
                      row.clientFiles.map((file, index) => {
                        return <span>{file}</span>;
                      })}
                  </FlexCol>
                </TableCell>
                <TableCell>
                  <Center>
                    <input type="checkbox" onChange={e => onChange(e, row)} checked={checks[row.pk]} />
                  </Center>
                </TableCell>
              </RowBase>
            );
          })}
        </Rows>
      </>

      <Pagination>
        <PaginationMobile showEverywhere={true} />
      </Pagination>
    </div>
  );
};

export default DocumentsDetailsFileList;

const GridBase = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr) 90px;
  width: 100%;
  text-align: left;
`;

const RowBase = styled(GridBase)`
  border: 1px solid var(--border);
  border-radius: 6px;
  border-bottom: 4px double var(--border);
  background-color: var(--defaultTableCell);
`;

const Rows = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const HeaderCell = styled.div`
  font-weight: bold;
  padding: 15px;
  border-radius: 6px;
  color: var(--defaultTableCellTitle);
  background-color: var(--defaultTableCell);
  font-size: 0.875rem;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

const TableCell = styled.div`
  margin: 0;
  text-align: left;
  padding: 18px 16px;
  border-bottom: 1px solid var(--border);
  overflow: auto;
  color: var(--mainText);
  font-size: 0.875rem;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

const Center = styled.div`
  width: 100%;
  text-align: center;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;
