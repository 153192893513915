import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DashboardTableRecord } from '../../../../types';
import TableDashboard, { DashboardTableHeaders } from '../../../Common/TableDashboard/TableDashboard';
import { RootState } from '../../../../../../redux/root';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDocumentPermit } from '../../../../../../redux/slices/documentsSlice';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../Routes/ClientRoutes';
import { loadDocumentsData } from '../../Documents/DocumentsTable';

const DocumentsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const vocabulary = useSelector((s: RootState) => s.general.vocabulary);
  const allDocuments = useSelector((s: RootState) => s.documents.AllPermitsWithDocs);

  const onClick = (id: number) => {
    const document = allDocuments.find(d => d.permitId === id);
    history.push(`${ROUTES.DOCUMENTS}?openDrawer=true`);
    //@ts-ignore
    dispatch(setSelectedDocumentPermit(document));
    loadDocumentsData(document, dispatch, vocabulary);
  };
  return (
    <TableDashboard headers={DashboardTableHeaders('View')} records={allDocuments} iconTitle="View document" onClick={onClick}>
      <VisibilityIcon />
    </TableDashboard>
  );
};

export default DocumentsTable;
