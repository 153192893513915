import { AxiosResponse } from 'axios';

import { apiPublic, api } from './api';
import { USER_TYPES_VALUES } from '../constants/forms';
import { FranchiseType } from './franchise';

export const getSignature = async (userId:number) => {
  const result = await api.request({
    method: 'GET',
    url: `/account/user-signatures/?user_id=${userId}`,
  });



  if(result.data.results.length === 0) {
    return "";
  }
  const sig = result.data.results[0].signature;
  return sig;
}

export const saveSignature = async(userId:number, sig:string) => {
  const data = {
    user_id: userId,
    blob_string: sig
  }
  const result = await api.request({
    method: 'POST',
    url: '/account/user-signatures/',
    data,
  });
}

function logIn(data: LogInDataType) {
  return apiPublic.request<UserAuthDataType>({
    method: 'POST',
    url: '/authentication/login/',
    data,
  });
}

export const sessionLogin = (json: string,userId: number) => {
  const data = {
    user: userId,
    log_text: json
  };
  return apiPublic.request<any>({
    method: 'POST',
    url: '/session_logging/session-activity-logs/',
    data,
  });
}


function refreshToken(refresh: string) {
  return apiPublic.request<RefreshTokenResponse>({
    method: 'POST',
    url: '/authentication/token/refresh/',
    data: {
      refresh,
    },
  });
}

function verifyToken(token: string) {
  return apiPublic.request({
    method: 'POST',
    url: '/authentication/token/verify/',
    data: {
      token,
    },
  });
}

function userLogout() {
  return api.request({
    method: 'POST',
    url: '/authentication/logout/',
  });
}

interface RefreshTokenResponse {
  access: string;
}

export interface LogInDataType {
  email: string;
  password: string;
}

export interface UserAuthDataType {
  accessToken: string;
  refreshToken: string;
  user: UserType;
}

export type AuthResponseType = AxiosResponse<
  UserAuthDataType
>;

export interface UserType {
  id: number;
  username?: string;
  companyName?: string;
  email: string;
  firstName: string;
  lastName: string;
  avatar?: string;
  address?: string;
  createdAt?: string;
  phoneNumber?: string;
  isEpsuperadmin: boolean;
  franchise: FranchiseType[];
  userRole: USER_TYPES_VALUES;
  defaultFranchiseId?: number;
  selectedFranchise?: number;
  signature?: string;
}

export default {
  logIn,
  verifyToken,
  refreshToken,
  userLogout,
};
