import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FormsReportType } from '../../api/reports';
import { FranchiseType } from '../../api/franchise';
import { UserType } from '../../api/userAuth';

export interface InspectionsReportState {
  showFormPopup: boolean;
  propmtMessage: string;
  records: FormsReportType[];
  reportFormLoader: boolean;
  franchiseIds: string;
  phoneInvalidMessage: string;
  contactPhone: string;
  errors: any;
  allContacts: UserType[];
  contact: UserType;
  notes: string;
}

const initialState: InspectionsReportState = {
  showFormPopup: false,
  propmtMessage: '',
  reportFormLoader: false,
  records: [],
  franchiseIds: '',
  phoneInvalidMessage: null,
  contactPhone: '',
  errors: {},
  allContacts: [],
  contact: {} as UserType,
  notes: '',
};

export const inspectionsReportSlice = createSlice({
  name: 'inspectionsReport',
  initialState,
  reducers: {
    setShowFormPopup: (state, action: PayloadAction<boolean>) => {
      state.showFormPopup = action.payload;
    },
    setRecords: (state, action: PayloadAction<any[]>) => {
      state.records = action.payload;
    },
    setReportsFormLoader: (state, action: PayloadAction<boolean>) => {
      state.reportFormLoader = action.payload;
    },
    setFranchisesForReport: (state, action: PayloadAction<string>) => {
      state.franchiseIds = action.payload;
    },
    setContactPhone: (state, action: PayloadAction<string>) => {
      state.contactPhone = action.payload;
    },
    setNotes: (state, action: PayloadAction<string>) => {
      state.notes = action.payload;
    },
    setPromptMessage: (state, action: PayloadAction<string>) => {
      state.propmtMessage = action.payload;
    },
    setErrors: (state, action: PayloadAction<any>) => {
      state.errors = action.payload;
    },
    setContact: (state, action: PayloadAction<UserType>) => {
      state.contact = action.payload;
    },
    setAllContacts: (state, action: PayloadAction<UserType[]>) => {
      state.allContacts = action.payload;
    },
    resetInspectionReports: state => {
      state.showFormPopup = false;
      state.records = [];
      state.reportFormLoader = false;
      state.franchiseIds = '';
      state.contactPhone = '';
      state.errors = {};
      state.contact = {} as UserType;
      state.allContacts = [];
      state.notes = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNotes,
  setErrors,
  setContact,
  setAllContacts,
  setContactPhone,
  setShowFormPopup,
  setPromptMessage,
  setRecords,
  setReportsFormLoader,
  resetInspectionReports,
  setFranchisesForReport,
} = inspectionsReportSlice.actions;

export default inspectionsReportSlice.reducer;
