import { ContactTypesType } from '../../api/contactType';
import { ProjectType, createClientProject } from '../../api/projects';
import { addBusinessDays, addDays, differenceInBusinessDays, format, isPast, isWeekend } from 'date-fns';
import { VocabularyItem, resetGeneral } from '../../redux/slices/generalSlice';
import { PermitInspectionsType, ProjectPermit, ReviewCommentStatuses, ReviewCommentType, ServiceTypeItemType } from './types';
import { FilterEntry, resetFilters, setFilters } from '../../redux/slices/filterSlice';
import { resetProjects } from '../../redux/slices/projectSlice';
import { resetMe } from '../../redux/slices/meSlice';
import { SchedulingType } from '../../api/scheduling';
import { resetInspections } from '../../redux/slices/inspectionsSlice';
import { resetClient } from '../../redux/slices/clientSlice';
import { resetActivities } from '../../redux/slices/allActivitiesSlice';
import { resetDocuments } from '../../redux/slices/documentsSlice';
import { resetConditions } from '../../redux/slices/conditionsSlice';
import { resetFees } from '../../redux/slices/feesSlice';
import { resetTickets } from '../../redux/slices/ticketsSlice';
import { resetDashboard } from '../../redux/slices/dashboardSlice';
import { apiSlice } from '../../redux/rtkQuery/apiSlice';

export const persistNewProject = async (data, vocabulary: VocabularyItem[]) => {
  const convertServiceType = (items: ServiceTypeItemType[]) => {
    const _items = items.reduce((vocArr: number[], item: ServiceTypeItemType) => {
      if (item.checked) {
        const vocaItem = vocabulary.find(voc => voc.title === item.title);
        if (vocaItem) {
          vocArr.push(vocaItem.id);
        }
      }

      return vocArr;
    }, []);
    return _items;
  };

  let payload = {
    ...data,
    name: data.projectName,
    contact_type: data.applicantType,
    zip_code: data.zipCode,
    franchise_id: data.franchise[0].id,
    serviceType: convertServiceType(data.serviceType),
    applicant_type: data.applicantType[0].title,
  };

  if (data.manager) {
    payload.project_manager = data.manager;
  }

  try {
    const result = await createClientProject(payload);
    return result;
  } catch (e) {
    return e;
  }
};

export const generateComparator = (direction, key) => {
  return (item1, item2) => {
    const returnBit = direction === 'ASC' ? 1 : -1;
    if (item1[key] < item2[key]) return returnBit;
    if (item1[key] > item2[key]) return -returnBit;
    return 0;
  };
};

type PendingProjectType = {
  address: string;
  attachments: any[];
  building: number;
  city: string;
  contactType: ContactTypesType[];
  createdAt: string;
  description: string;
  fanchisedId: number;
  id: number;
  name: string;
  projectManager: number;
  projectStatus: string; //"Sent"
  state: string;
  street: string;
  zipCode: string;
};
export const normalizeProjectPermits = (
  items: ProjectType[],
  pendingItems: PendingProjectType[],
  vocabularies: VocabularyItem[],
): ProjectPermit[] => {
  let projectPermits = items.reduce((projectPermitsItem, project) => {
    project.permits.forEach(permit => {
      projectPermitsItem.push({
        ...project,
        permitId: permit.id,
        permitName: permit.name,
        namePermitName: `${project.name}/${permit.name}`,
        permitDescription: permit.description,
        status: vocabularies.find(v => v.id === permit.status).title,
      });
    });
    return projectPermitsItem;
  }, []);
  pendingItems.forEach(item => {
    projectPermits.push({
      name: item.name,
      permitName: item.name,
      address: item.address,
      status: item.projectStatus,
      namePermitName: item.name,
    });
  });
  return projectPermits;
};

export const updatePagination = (pageIndex: number, pageSize: number, projectsCollection: any[]) => {
  const start = pageIndex * pageSize;
  let end = (pageIndex + 1) * pageSize;

  if (end > projectsCollection.length) {
    return projectsCollection.slice(start);
  }

  return projectsCollection.slice(start, end);
};

export const updateFilters = (name: string, value: string, filters: any[], dispatch): void => {
  const foundItem = filters.find(f => f.filterName === name);
  let newArr;
  if (!foundItem) {
    newArr = [
      ...filters,
      {
        filterName: name,
        filterValue: value?.toLowerCase(),
      },
    ];
  } else {
    newArr = filters.map(f => {
      if (f.filterName === name) {
        return {
          filterName: name,
          filterValue:value.toLowerCase(),
        };
      }
      return f;
    });
  }
  dispatch(setFilters(newArr));
};

export const applyFilters = (projectsCollection: ProjectPermit[], filters: FilterEntry[]) => {
  return projectsCollection;
};

export const filterActiveRecords = (records: ProjectPermit[], showActiveOnly: boolean): ProjectPermit[] => {
  return records.reduce((recordArr, record) => {
    if (!showActiveOnly) {
      recordArr.push(record);
    } else {
      if (record.status !== 'Completed' && record.status !== 'Withdrawn') {
        recordArr.push(record);
      }
    }
    return recordArr;
  }, []);
};

export const clearStore = dispatch => {
  dispatch(resetMe());
  dispatch(resetInspections());
  dispatch(resetFilters());
  dispatch(resetClient());
  dispatch(resetGeneral());
  dispatch(resetProjects());
  dispatch(resetActivities());
  dispatch(resetDocuments());
  dispatch(resetConditions());
  dispatch(resetFees());
  dispatch(resetTickets());
  dispatch(resetDashboard());
  dispatch(apiSlice.util.resetApiState())
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {
    type: mime,
  });
}

export const downloadFile = payload => {
  try {
    var myFile = dataURLtoFile(`data:application/pdf;application/docx;base64,${payload.data.file}`, payload.data.fileName);
    let url = window.URL.createObjectURL(myFile);
    let a = document.createElement('a');
    a.href = url;
    a.download = payload.data.fileName;
    a.click();
  } catch (err) {
    console.log(err);
  }
};

export const validateInspectionDate = (date: Date, schedules: SchedulingType[], now?: Date) => {
  //pending - today(before 4pm) only following business day except holidays (holiday Schedule? where do I see it)
  //                after 4pm  only - only after the second busines day
  // no same dates or dates from the past
  // Note to inspector - which field holds the data - this is not on the list from Tatiana  -- Use "information"

  //1. the date is in the past
  //2. the date is on a holiday
  //3. the date is today
  //4. the date is tomorrow but its after 4:00pm

  const inspectionDate = new Date(date);
  const _now = now || new Date();
  if (format(_now, 'MM/dd/yyyy') === format(inspectionDate, 'MM/dd/yyyy') && _now.getHours() < 16) {
    return 'Inspection Date has to be in the future';
  }
  if (isPast(inspectionDate)) {
    return 'Inspection Dates can not be in the past!';
  }

  const holiday = schedules.find(s => s.date === format(inspectionDate, 'MM/dd/yyyy'));
  if (holiday) {
    return `Inspection Date can not be on a holiday (${holiday.description})`;
  }

  //after 4:00pm
  if (_now.getHours() > 15) {
    //edge case if we are on Friday you can only schedule on Tue
    if(_now.getDay() === 5 && date.getDay() === 1){
      return 'For inspection requests made after 4:00 PM on Friday, the earliest available appointment will be Tuesday of the following week or later.';
    }
    const tomorrow = addDays(_now, 1);
    //this is not applicaple to Fridays (that was verify before) but any other day.
    if (differenceInBusinessDays(inspectionDate, tomorrow) === 0 && _now.getDay() !== 5) {
      return 'For inspection requests made after 4:00 PM, the earliest available date will be two days from the request date.';
    }
  }

  if (isWeekend(inspectionDate)) {
    return 'Inspection Dates can not be in the weekend!';
  }
  return null;
};

export const getNextInspectionDate = () => {
  const now = new Date();
  const nextDate = now.getHours() > 15 ? addBusinessDays(now, 3) : addBusinessDays(now, 2); //MM/dd/yyyy
  return format(nextDate, 'MM/dd/yyyy');
};

export const findInspection = (allInspections: PermitInspectionsType[], inspectionId: number) => {
  return {};
};

export const sortTableHeader = (tableSortingDirection: string, tableSortingKey: string, items: any[]) => {
  let _items;
  if(items.length === 0){
    return [];
  }
  let item = items[0];
  let amount = item[tableSortingKey];
  amount = amount ?  amount.replace(/\$/, '') : '';
  if (isNaN(Number(amount))) {
    switch (tableSortingDirection) {
      case 'NONE':
        return items;
      case 'ASC':
        _items = items.sort((a, b) => {
          if (a[tableSortingKey] > b[tableSortingKey]) return 1;
          if (a[tableSortingKey] < b[tableSortingKey]) return -1;
          return 0;
        });
        return _items;
      case 'DESC':
        _items = items.sort((a, b) => {
          if (a[tableSortingKey] > b[tableSortingKey]) return -1;
          if (a[tableSortingKey] < b[tableSortingKey]) return 1;
          return 0;
        });
        return _items;
    }
  } else {
    switch (tableSortingDirection) {
      case 'NONE':
        return items;
      case 'ASC':
        _items = items.sort((a, b) => {
          if (Number(a[tableSortingKey]) > Number(b[tableSortingKey])) return 1;
          if (Number(a[tableSortingKey]) < Number(b[tableSortingKey])) return -1;
          return 0;
        });
        return _items;
      case 'DESC':
        _items = items.sort((a, b) => {
          if (Number(a[tableSortingKey]) > Number(b[tableSortingKey])) return -1;
          if (Number(a[tableSortingKey]) < Number(b[tableSortingKey])) return 1;
          return 0;
        });
        return _items;
    }
  }
  return items;
};

export const  getStatusesFromApi = (result: any) => {
  const statuses: ReviewCommentType[] = result.data.map(item => ({
      id: item.id,
      isClientCommentVisible: item.isClientCommentVisible,
      reference: item.reference,
      reviewerComment: item.reviewerComment,
      status: { label: item.status, value: ReviewCommentStatuses.find(i => i.label === item.status)?.value || 0 }
  }))
  return statuses;
}

export const breakLines = (text: string) => {
  const result = text.replace(/\s+/g, " ");
  const words = result.split(/ /);
  const NewLine = '\r\n';
  const charsPerLine = 78;
  const brokenLines = words.reduce((lines,word)=>{    
    const lastLineBreak = lines.lastIndexOf(NewLine)
    const lastLine = lines.substring(lastLineBreak);
    const candidate = lastLine + ' ' + word;
    if(candidate.length < charsPerLine){
      lines = lines + ' ' + word;
    } else {
      lines = lines + ' ' + word + NewLine;
    }
    return lines;
  },'')
  return brokenLines;
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const currency = (amount: number) => formatter.format(amount);