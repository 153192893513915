import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Logo_Gold, Logo_Sky } from '../../../../../constants/menu';

type Props = {
    allowVisibility: boolean;
}
const DetailsIcon:FC<Props> = ({allowVisibility}) => {
    return (
        <DetailsWrapper>
            {allowVisibility && <VisibilityWrapper><Visibility/></VisibilityWrapper>}
            {!allowVisibility && <BlockVisibility>
                <VisibilityOff />
            </BlockVisibility>}
        </DetailsWrapper>
    );
};

export default DetailsIcon;

const VisibilityWrapper = styled.div`
  color: ${Logo_Gold}
`;

const DetailsWrapper = styled.div`
    right: 27px;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media only screen and (max-width: 840px) {
        position: absolute;
  }  
`;

const BlockVisibility= styled.div`
 color: red;
 cursor: default;
`;