import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { InspectionItemType } from '../../../../types';
import StaticField from '../../../Common/Controls/StaticField';
import OtherField from '../../../Common/Controls/OtherField';
import DatePickerControl from '../../../Common/Controls/DatePickerControl';
import { Logo_Sky } from '../../../../../../constants/menu';
import { useDispatch, useSelector } from 'react-redux';
import { setInspectionApiResults } from '../../../../../../redux/slices/inspectionsSlice';
import { RootState } from '../../../../../../redux/root';
import ContactDropdown from './ContactDropdown';
import { setContact, setContactPhone, setErrors, setNotes } from '../../../../../../redux/slices/InspectionsReportSlice';

type Props = {
  inspection: InspectionItemType;
  setInspectionDate: Function;
};
const InspectionsControls: FC<Props> = ({ inspection, setInspectionDate }) => {
  const [date, setDate] = useState<string>(inspection.inspectionDate);
  const dispatch = useDispatch();
  const [headerMessage] = useState<string>("*if you wish to provide details for a field contact and their phone number, kindly include them in the notes below. Also, please note that time frame requests will not be honored.")
  const { isApiError, apiMessage } = useSelector((s: RootState) => s.inspections);
  const { contactPhone,errors, allContacts, contact, notes } = useSelector((s: RootState) => s.inspectionReports);
  useEffect(() => {    
    return () => {
      dispatch(setInspectionApiResults({ apiMessage: undefined, isApiError: false }));
    };
  }, []);

  useEffect(() => {
    if(allContacts.length > 0 && inspection.contactId){
      const selContact = allContacts.find(c => c.id === Number(inspection.contactId));
      if(selContact){
        dispatch(setContact(selContact));
      }
    }
  }, [allContacts])
  useEffect(() => {
    dispatch(setContactPhone(contact.phoneNumber));
  }, [contact])
  const setDateValue = e => {
    if(errors.inspectionDate){
      const _errors = {
        ...errors,
      };
      delete _errors.inspectionDate;
      dispatch(setErrors(_errors))
    }
    setDate(e);
    setInspectionDate(e);
  };

  const onChange = e => {
    dispatch(setContactPhone(e.target.value));
    const _errors = {...errors,'contactPhone':null}
    dispatch(setErrors(_errors));
  }
  return (
    <Controls>
      <TopGrid>
        <StaticField label="Project Name:" value={inspection.projectName} />
        <StaticField label="Permit Name:" value={inspection.permitName} />
        <StaticField label="Permit Address:" value={inspection.projectAddress} />
        <StaticField label="Jurisdiction:" value={inspection.projectJurisdiction} />
        <StaticField label="Project Manager:" value={`${inspection.managerFirstName} ${inspection.managerLastName}`} />
        <StaticField label="Inspection Number:" value={`${inspection.id}`} />
      </TopGrid>

      <OtherFields>
        <OtherField label="Inspection Type" value={inspection.activityTypeName} />
        <OtherField label="Inspection Status" value={inspection.statusName} />
        <OtherField invalidMessage={errors['inspectionDate']} label="Inspection Date" value={inspection.inspectionDate}>
          <DatePickerControl value={date ? new Date(date) : null} onChange={setDateValue} />
        </OtherField>
        <OtherField label="Contact Name" value={`${contact.companyName} ${contact.firstName} ${contact.lastName}`} >
           <ContactDropdown inspection={inspection} />
        </OtherField>
        <OtherField isText={true} invalidMessage={errors['contactPhone']} label="Phone Number" value={contactPhone}>
          <PhoneNumber value={contactPhone} onChange={onChange}/>
        </OtherField>

        {apiMessage && <SuccessMessage isApiError={isApiError}>{apiMessage}</SuccessMessage>}
      </OtherFields>
      <NotesWrapper>
        <OtherField label="Notes" value="" header2={headerMessage}>
          <Information rows={4} value={notes} onChange={e => dispatch(setNotes(e.target.value))} />
        </OtherField>
        {/* <Disclaimer>
          *if you wish to provide details for a field contact and their phone number, kindly include them in the notes below. Also, please
          note that time frame requests will not be honored.
        </Disclaimer> */}
      </NotesWrapper>
    </Controls>
  );
};

export default InspectionsControls;

const PhoneNumber = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  &:focus-visible {
    border: none;
    outline: none;
  }
`;
const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const NotesWrapper = styled.div`
  margin: 0 86px;
`;

export const SuccessMessage = styled.div<{ isApiError: boolean }>`
  color: ${({ isApiError }) => (isApiError ? 'red' : 'green')};
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
`;

export const Controls = styled.div`
  height: 100%;
  width: 100%;
  max-height: 64vh;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  overflow: auto;
`;

export const OtherFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 30px;
  margin-top: 10px;
  margin-inline: 86px;
  height: 459px;
`;

export const Information = styled.textarea`
  width: 100%;
  overflow: auto;
  outline: none;
  border: none;
`;

const Disclaimer = styled.div`
  color: ${Logo_Sky};
  font-size: 12px;
  font-style: italic;
  position: relative;
  top: -19px;
`;
