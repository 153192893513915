import { Button } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CheckboxRounded, Loader } from '../../atoms';
import { InputSelectWithLabel } from '../../molecules';
import ContactMail from '@material-ui/icons/ContactMail';
import SaveAlt from '@material-ui/icons/SaveAlt';
import { useParams } from 'react-router';
import { getPermitUsers } from '../../api/users';
import { useSendCorrectionEmailMutation } from '../../redux/rtkQuery/apiSlice';
import { createPermitDocument } from '../../api/projectDocuments';

const ReviewCommentsPopup = ({ setShowPopup, generatePDFAutoPageSplit, setShowReviewCommentLtr }) => {
  const [save, setSave] = useState<boolean>(false);
  const [send, setSend] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [sendCorrectionEmail] = useSendCorrectionEmailMutation();

  //@ts-ignore
  const { permitId } = useParams();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (permitId) {
      const load = async () => {
        const result: any = await getPermitUsers({ permit_id: permitId, user_role: 3 });
        setUsers(result.data);
      };
      load();
    }
  }, []);

  const onClose = () => {
    setSave(false);
    setSend(false);
    setShowPopup(false);
  };

  const onClick = async () => {
    setLoading(true);
    const letterName = 'CorrectionLetter';
    const file = await generatePDFAutoPageSplit();
    if (save) {
      const data = {
        permit: permitId,
        name: letterName,
        status: 47,
        isClientAvailable: true,
        correctionFile: file
      }
      await createPermitDocument(data);
    }

    if (send) {
      
      const formData = new FormData();
      const ids = selectedContacts.map(c => c.id).join(',');
      formData.append('recipients', ids);
      formData.append('permit_id', permitId);
      formData.append('file_name', letterName);
      formData.append('file', file);
      await sendCorrectionEmail(formData);
    }

    onClose();
    setShowReviewCommentLtr(false);
  };

  const handleSelectContacts = selected => {
    setSelectedContacts(selected);
  };

  const disableOK = () => {
    if (loading) {
      return true;
    }
    if (!save && !send) {
      return true;
    }

    if (send && selectedContacts.length === 0) {
      return true;
    }
    return false;
  };
  return (
    <Wrapper>
      <Body>
        <CBWrapper>
          <CheckboxRounded onChange={e => setSave(e.target.checked)} checked={save} />
          <IconWrapper>
            <SaveAlt />
          </IconWrapper>
          <CheckboxLabel>Save PDF to documents</CheckboxLabel>
        </CBWrapper>
        <CBWrapper>
          <CheckboxRounded onChange={e => setSend(e.target.checked)} checked={send} />
          <IconWrapper>
            <ContactMail />
          </IconWrapper>
          <CheckboxLabel>Send PDF to client via email</CheckboxLabel>
        </CBWrapper>
        {send && (
          <InputWrapper>
            <InputSelectWithLabel
              isMulti
              // selectAllActive
              centered
              placeholder=""
              label="Contacts"
              getOptionValue={(item: any) => item?.id}
              getOptionLabel={(item: any) =>
                // eslint-disable-next-line no-nested-ternary
                item.name
                  ? item.name
                  : `${item.firstName} ${item.lastName}${
                      item?.companyName || item?.parentCompany ? ` - ${item?.companyName || item?.parentCompany}` : ''
                    }`
              }
              isRequired
              options={users}
              onChange={handleSelectContacts}
            />
          </InputWrapper>
        )}
      </Body>
      <Buttons>
        <Button color="primary" variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={onClick} disabled={disableOK()}>
          OK
        </Button>
        {loading && (
          <Loading>
            <Loader />
          </Loading>
        )}
      </Buttons>
    </Wrapper>
  );
};

export default ReviewCommentsPopup;

const Loading = styled.div`
  position: absolute;
  scale: 0.6;
  right: 8px;
  bottom: 5px;
`;

const InputWrapper = styled.div`
  top: 4px;
  left: 37px;
  position: relative;
  width: 297px;
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 4;
  top: 68px;
  left: 152px;
  width: 32vw;
  background-color: #fff;
  height: fit-content;
  box-shadow: 16px 16px 8px rgba(120, 120, 120, 0.9);
  border: 3px solid;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  flex: 1;
  width: 300px;
  margin: auto;
`;

const Buttons = styled.div`
  height: 62px;
  padding: 14px;
  display: flex;
  justify-content: space-around;
  button {
    width: 150px;
  }
`;

const CBWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 25px;
  input {
    padding-right: 20px;
  }
`;

const CheckboxLabel = styled.div`
  position: relative;
  top: 7px;
`;

const IconWrapper = styled.div`
  position: relative;
  top: 7px;
  padding-right: 10px;
`;
